// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { EReceptaMigrationStatus, EReceptaMigrationSourceModule, EReceptaMigrationStartParams } from './models';

const paths = {
  statusMigracji: 'api/ereceptamigration/StatusMigracji',
  pobierzModulyERecepty: 'api/ereceptamigration/PobierzModulyERecepty',
  uruchomMigracje: 'api/ereceptamigration/UruchomMigracje',
};

function statusMigracji(): Promise<EReceptaMigrationStatus> {
  return axios.request<EReceptaMigrationStatus>({
    url: paths.statusMigracji,
    method: 'get',
  }).then(res => res.data);
}

function pobierzModulyERecepty(): Promise<EReceptaMigrationSourceModule[]> {
  return axios.request<EReceptaMigrationSourceModule[]>({
    url: paths.pobierzModulyERecepty,
    method: 'get',
  }).then(res => res.data);
}

function uruchomMigracje(data: EReceptaMigrationStartParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.uruchomMigracje,
    method: 'post',
    data,
  });
}

export const eReceptaMigrationService = {
  paths,
  statusMigracji,
  pobierzModulyERecepty,
  uruchomMigracje,
};
