import React from "react";
import { ReceptyWynikWyszukiwaniaLokalnychRecept } from "../../../api/models";
import { FindPrescriptionsResultItem } from "./FindPrescriptionsResultItem";

interface FindPrescriptionsResultProps {
  result?: ReceptyWynikWyszukiwaniaLokalnychRecept;
}

interface FindPrescriptionsResultState {}

export class FindPrescriptionsResult extends React.PureComponent<
  FindPrescriptionsResultProps,
  FindPrescriptionsResultState
> {
  constructor(props: FindPrescriptionsResultProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { result } = this.props;

    if (!result) return <h3>Wybierz kryteria i kliknij "Szukaj"</h3>;

    const recepty = result.recepty || [];
    if (!recepty.length) {
      return <h3>Brak recept dla wskazanych warunków</h3>;
    }

    return (
      <>
        {recepty.map(x => (
          <FindPrescriptionsResultItem key={x.id} recepta={x} />
        ))}
      </>
    );
  }
}
