import { Button, DialogContent, DialogProps, Grid, GridItem, useDataLoader, useDialogSize } from "@optimed/react";
import { receptyService, receptyTestService } from "../../../api";

interface PrescriptionPacketPrintDialogProps extends DialogProps<void> {
  idPakietu?: number;
  xml?: string;
}

export function PrescriptionPacketPrintDialog(props: PrescriptionPacketPrintDialogProps) {
  const { idPakietu, xml, dialog } = props;

  useDialogSize("95%", "95%");

  const [frameSrc] = useDataLoader(() =>
    (idPakietu
      ? receptyService.wydrukPakietuRecept({ idPakietu })
      : receptyTestService.wydrukPakietuZXml({ xml: xml || "" })
    )
      .then(res => res.text())
      .then(res => `data:text/html;charset=utf-8,${encodeURIComponent(res)}`)
  );

  const dialogTitle = idPakietu ? `Wydruk pakietu recept id=${idPakietu}` : `Wydruk pakietu recept z XML`;

  return (
    <DialogContent header={dialogTitle}>
      <Grid>
        <GridItem rowTemplate="1fr">
          {frameSrc ? (
            <iframe src={frameSrc} title="print" style={{ width: "100%", height: "100%" }} />
          ) : (
            "Wczytywanie..."
          )}
        </GridItem>
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={() => dialog.close()}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}
