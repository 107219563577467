import React from "react";
import { ZmListaZdarzenMedycznychResponse, ZmListaZdarzenMedycznychResponseItem } from "../../../api/models";
import { EncounterQueryP1ResultItem } from "./EncounterQueryP1ResultItem";

interface EncounterQueryP1ResultProps {
  result?: ZmListaZdarzenMedycznychResponse;
  onOpenDetails: (x: ZmListaZdarzenMedycznychResponseItem) => any;
  onRemove: (x: ZmListaZdarzenMedycznychResponseItem) => any;
}

export function EncounterQueryP1Result(props: EncounterQueryP1ResultProps) {
  const { result, onOpenDetails, onRemove } = props;

  if (!result) return <h3>Wybierz kryteria i kliknij "Szukaj"</h3>;

  const list = result.items || [];
  if (!list.length) {
    return <h3>Brak zdarzeń dla wskazanych warunków</h3>;
  }

  return (
    <>
      {list.map(x => (
        <EncounterQueryP1ResultItem key={x.idZasobu} item={x} onOpenDetails={onOpenDetails} onRemove={onRemove} />
      ))}
    </>
  );
}
