import { Button, Form, FormItem, InputGroup, TextInput } from "@optimed/react";
import { useState } from "react";

interface EncounterQueryP1IdSelectorProps {
  onShowEncounterDetails: (encounterId: string) => any;
}

export function EncounterQueryP1IdSelector(props: EncounterQueryP1IdSelectorProps) {
  const { onShowEncounterDetails } = props;
  const [encounterId, setEncounterId] = useState<string>("");
  return (
    <Form layout="vertical">
      <FormItem label="Identyfikator zasobu Encounter">
        <InputGroup sm>
          <TextInput value={encounterId} onChange={setEncounterId} />
          <Button disabled={!encounterId} onClick={() => encounterId && onShowEncounterDetails(encounterId)}>
            Pokaż szczegóły
          </Button>
        </InputGroup>
      </FormItem>
    </Form>
  );
}
