import { SelectInput, useDataLoader } from "@optimed/react";
import React from "react";
import { eReceptaMigrationService } from "../../api";
import { EReceptaMigrationSourceModule } from "../../api/models";

interface EReceptaMigrationSourceSelectorProps {
  value: string | EReceptaMigrationSourceModule;
  onChange: (value: string | EReceptaMigrationSourceModule) => any;
}

export function EReceptaMigrationSourceSelector(props: EReceptaMigrationSourceSelectorProps) {
  const { value, onChange } = props;
  const [sources] = useDataLoader(() =>
    eReceptaMigrationService.pobierzModulyERecepty().then(res => {
      const preferred = res.find(x => x.preferred);
      if (preferred) {
        onChange(preferred);
      }
      return res;
    })
  );

  return (
    <SelectInput
      items={sources || []}
      value={value}
      onChange={x => onChange(x || "")}
      nonStrict
      display={formatView}
      itemRender={itemRender}
    />
  );
}

function formatView(x: string | EReceptaMigrationSourceModule) {
  if (!x) return "";

  if (typeof x === "string") {
    return x;
  }

  return `${x.sourceUrl} - e-Recepta zainstalowana w ramach instancji ${x.instanceName}`;
}

function itemRender(x: string | EReceptaMigrationSourceModule) {
  return formatView(x);
}
