import { AppWithDefaultAuth } from "@optimed/react";
import React from "react";
import { Main } from "../domain/main/Main";

export function App() {
  return (
    <AppWithDefaultAuth appName="Optimed P1">
      <Main />
    </AppWithDefaultAuth>
  );
}
