import { Badge } from "@optimed/react";
import React from "react";
import { ReceptyStatusPakietuRecept, receptyStatusPakietuReceptDescription } from "../../api/models";

export const statusLabelType = {
  [ReceptyStatusPakietuRecept.PrzygotowanyDoWysylki]: "info",
  [ReceptyStatusPakietuRecept.PomyslnieWyslano]: "success",
  [ReceptyStatusPakietuRecept.BladWysylania]: "danger",
  [ReceptyStatusPakietuRecept.PrzetwarzanieP1]: "info",
};

interface StatusPakietuLabelProps {
  status: ReceptyStatusPakietuRecept | undefined;
}

export function StatusPakietuLabel(props: StatusPakietuLabelProps) {
  const { status } = props;

  if (!status) {
    return <Badge type="secondary">?</Badge>;
  }

  return (
    <Badge style={{ width: "200px", display: "inline-block" }} type={statusLabelType[status] as any}>
      {receptyStatusPakietuReceptDescription[status]}
    </Badge>
  );
}
