import { Button, DialogContent, DialogProps, Grid, GridItem, useDataLoader, useDialogSize } from "@optimed/react";
import { receptyService, receptyTestService } from "../../../api";

interface PrescriptionPrintDialogProps extends DialogProps<void> {
  idRecepty?: number;
  xml?: string;
}

export function PrescriptionPrintDialog(props: PrescriptionPrintDialogProps) {
  const { idRecepty, xml, dialog } = props;

  useDialogSize("95%", "95%");
  const [frameSrc] = useDataLoader(() =>
    (idRecepty ? receptyService.wydrukRecepty({ idRecepty }) : receptyTestService.wydrukReceptyZXml({ xml: xml || "" }))
      .then(res => res.text())
      .then(res => `data:text/html;charset=utf-8,${encodeURIComponent(res)}`)
  );

  const dialogTitle = idRecepty ? `Wydruk recepty id=${idRecepty}` : `Wydruk recepty z XML`;

  return (
    <DialogContent header={dialogTitle}>
      <Grid gap>
        <GridItem rowTemplate="1fr">
          {frameSrc ? (
            <iframe src={frameSrc} title="print" style={{ width: "100%", height: "100%" }} />
          ) : (
            "Wczytywanie..."
          )}
        </GridItem>
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={() => dialog.close()}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}
