// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ReceptyOdczytDokumentuSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaOdczytDokumentuSkierowaniaResult, ReceptyOdczytDokumentuSkierowaniaDoRealizacjiDaneZapytanieZKontekstemP1, SkierowaniaOdczytDokumentuSkierowaniaDoRealizacjiResult, ReceptyPrzyjecieDoRealizacjiSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaPrzyjecieDoRealizacjiSkierowaniaResult, ReceptyOdmowaPrzyjeciaDoRealizacjiSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaOdmowaPrzyjeciaDoRealizacjiSkierowaniaResult, ReceptyRezygnacjaZRealizacjiSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaRezygnacjaZRealizacjiSkierowaniaResult, ReceptyPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaResult, ReceptyZakonczenieRealizacjiSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaZakonczenieRealizacjiSkierowaniaResult, ReceptyZapisDokumentuSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaZapisDokumentuSkierowaniaResult, SkierowaniaWyslijSkierowanieDoPodpisuPzResult, ReceptyZapisDokumentuAnulowaniaSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaWyslijAnulowanieSkierowanieDoPodpisuPzResult, SkierowaniaZapisDokumentuAnulowaniaSkierowaniaResult, SkierowaniaWeryfikacjaDokumentuSkierowaniaResult, ReceptyWyszukanieSkierowanWystawiajacegoDaneZapytanieZKontekstemP1, SkierowaniaWyszukanieSkierowanWystawiajacegoResult, ReceptyWyszukanieSkierowanUslugobiorcyDaneZapytanieZKontekstemP1, SkierowaniaWyszukanieSkierowanUslugobiorcyResult, ReceptyOdczytDanychDostepowychSkierowaniaDaneZapytanieZKontekstemP1, SkierowaniaOdczytDanychDostepowychSkierowaniaResult, SkierowaniaSkierowanieWystawione, SkierowaniaSkierowanieLokalneDto } from './models';

const paths = {
  odczytDokumentuSkierowania: 'api/skierowania/OdczytDokumentuSkierowania',
  odczytDokumentuSkierowaniaDoRealizacji: 'api/skierowania/OdczytDokumentuSkierowaniaDoRealizacji',
  przyjecieDoRealizacjiSkierowania: 'api/skierowania/PrzyjecieDoRealizacjiSkierowania',
  odmowaPrzyjeciaDoRealizacjiSkierowania: 'api/skierowania/OdmowaPrzyjeciaDoRealizacjiSkierowania',
  rezygnacjaZRealizacjiSkierowania: 'api/skierowania/RezygnacjaZRealizacjiSkierowania',
  przekazanieInformacjiOBrakachDokumentacjiSkierowania: 'api/skierowania/PrzekazanieInformacjiOBrakachDokumentacjiSkierowania',
  zakonczenieRealizacjiSkierowania: 'api/skierowania/ZakonczenieRealizacjiSkierowania',
  zapisDokumentuSkierowania: 'api/skierowania/ZapisDokumentuSkierowania',
  wyslijSkierowanieDoPodpisuPZ: 'api/skierowania/WyslijSkierowanieDoPodpisuPZ',
  wyslijAnulowanieSkierowanieDoPodpisuPZ: 'api/skierowania/WyslijAnulowanieSkierowanieDoPodpisuPZ',
  zapisDokumentuAnulowaniaSkierowania: 'api/skierowania/ZapisDokumentuAnulowaniaSkierowania',
  zapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZ: 'api/skierowania/ZapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZ',
  weryfikacjaDokumentuSkierowania: 'api/skierowania/WeryfikacjaDokumentuSkierowania',
  wyszukanieSkierowanWystawiajacego: 'api/skierowania/WyszukanieSkierowanWystawiajacego',
  wyszukanieSkierowanUslugobiorcy: 'api/skierowania/WyszukanieSkierowanUslugobiorcy',
  odczytDanychDostepowychSkierowania: 'api/skierowania/OdczytDanychDostepowychSkierowania',
  wydrukSkierowaniaPdf: 'api/skierowania/WydrukSkierowaniaPdf',
  wydrukSkierowaniaLokalnegoPdf: 'api/skierowania/WydrukSkierowaniaLokalnegoPdf',
  wydrukSkierowaniaHtml: 'api/skierowania/WydrukSkierowaniaHtml',
  wydrukSkierowaniaWystawionegoHtml: 'api/skierowania/WydrukSkierowaniaWystawionegoHtml',
  wydrukInformacjiOSkierowaniuWystawionymHtml: 'api/skierowania/WydrukInformacjiOSkierowaniuWystawionymHtml',
  wydrukInformacjiOSkierowaniuWystawionymPdf: 'api/skierowania/WydrukInformacjiOSkierowaniuWystawionymPdf',
  wystawioneSkierowania: 'api/skierowania/WystawioneSkierowania',
  skierowanieLokalne: 'api/skierowania/SkierowanieLokalne',
  zapiszSkierowaniePodpisanePZ: 'api/skierowania/ZapiszSkierowaniePodpisanePZ',
};

interface OdczytDokumentuSkierowaniaParams {
  zwrocXml?: boolean;
  zwrocHtml?: boolean;
  operationSecondsTimeout?: number;
}

function odczytDokumentuSkierowania(args: { params: OdczytDokumentuSkierowaniaParams; data: ReceptyOdczytDokumentuSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaOdczytDokumentuSkierowaniaResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaOdczytDokumentuSkierowaniaResult>({
    url: paths.odczytDokumentuSkierowania,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface OdczytDokumentuSkierowaniaDoRealizacjiParams {
  zwrocXml?: boolean;
  zwrocHtml?: boolean;
  operationSecondsTimeout?: number;
}

function odczytDokumentuSkierowaniaDoRealizacji(args: { params: OdczytDokumentuSkierowaniaDoRealizacjiParams; data: ReceptyOdczytDokumentuSkierowaniaDoRealizacjiDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaOdczytDokumentuSkierowaniaDoRealizacjiResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaOdczytDokumentuSkierowaniaDoRealizacjiResult>({
    url: paths.odczytDokumentuSkierowaniaDoRealizacji,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function przyjecieDoRealizacjiSkierowania(data: ReceptyPrzyjecieDoRealizacjiSkierowaniaDaneZapytanieZKontekstemP1): Promise<SkierowaniaPrzyjecieDoRealizacjiSkierowaniaResult> {
  return axios.request<SkierowaniaPrzyjecieDoRealizacjiSkierowaniaResult>({
    url: paths.przyjecieDoRealizacjiSkierowania,
    method: 'post',
    data,
  }).then(res => res.data);
}

function odmowaPrzyjeciaDoRealizacjiSkierowania(data: ReceptyOdmowaPrzyjeciaDoRealizacjiSkierowaniaDaneZapytanieZKontekstemP1): Promise<SkierowaniaOdmowaPrzyjeciaDoRealizacjiSkierowaniaResult> {
  return axios.request<SkierowaniaOdmowaPrzyjeciaDoRealizacjiSkierowaniaResult>({
    url: paths.odmowaPrzyjeciaDoRealizacjiSkierowania,
    method: 'post',
    data,
  }).then(res => res.data);
}

function rezygnacjaZRealizacjiSkierowania(data: ReceptyRezygnacjaZRealizacjiSkierowaniaDaneZapytanieZKontekstemP1): Promise<SkierowaniaRezygnacjaZRealizacjiSkierowaniaResult> {
  return axios.request<SkierowaniaRezygnacjaZRealizacjiSkierowaniaResult>({
    url: paths.rezygnacjaZRealizacjiSkierowania,
    method: 'post',
    data,
  }).then(res => res.data);
}

function przekazanieInformacjiOBrakachDokumentacjiSkierowania(data: ReceptyPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaDaneZapytanieZKontekstemP1): Promise<SkierowaniaPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaResult> {
  return axios.request<SkierowaniaPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaResult>({
    url: paths.przekazanieInformacjiOBrakachDokumentacjiSkierowania,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zakonczenieRealizacjiSkierowania(data: ReceptyZakonczenieRealizacjiSkierowaniaDaneZapytanieZKontekstemP1): Promise<SkierowaniaZakonczenieRealizacjiSkierowaniaResult> {
  return axios.request<SkierowaniaZakonczenieRealizacjiSkierowaniaResult>({
    url: paths.zakonczenieRealizacjiSkierowania,
    method: 'post',
    data,
  }).then(res => res.data);
}

interface ZapisDokumentuSkierowaniaParams {
  zwrocXml?: boolean;
}

function zapisDokumentuSkierowania(args: { params: ZapisDokumentuSkierowaniaParams; data: ReceptyZapisDokumentuSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaZapisDokumentuSkierowaniaResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaZapisDokumentuSkierowaniaResult>({
    url: paths.zapisDokumentuSkierowania,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface WyslijSkierowanieDoPodpisuPZParams {
  successUrl?: string;
  failurUrl?: string;
}

function wyslijSkierowanieDoPodpisuPZ(args: { params: WyslijSkierowanieDoPodpisuPZParams; data: ReceptyZapisDokumentuSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaWyslijSkierowanieDoPodpisuPzResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaWyslijSkierowanieDoPodpisuPzResult>({
    url: paths.wyslijSkierowanieDoPodpisuPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface WyslijAnulowanieSkierowanieDoPodpisuPZParams {
  successUrl?: string;
  failurUrl?: string;
}

function wyslijAnulowanieSkierowanieDoPodpisuPZ(args: { params: WyslijAnulowanieSkierowanieDoPodpisuPZParams; data: ReceptyZapisDokumentuAnulowaniaSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaWyslijAnulowanieSkierowanieDoPodpisuPzResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaWyslijAnulowanieSkierowanieDoPodpisuPzResult>({
    url: paths.wyslijAnulowanieSkierowanieDoPodpisuPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface ZapisDokumentuAnulowaniaSkierowaniaParams {
  zwrocXml?: boolean;
}

function zapisDokumentuAnulowaniaSkierowania(args: { params: ZapisDokumentuAnulowaniaSkierowaniaParams; data: ReceptyZapisDokumentuAnulowaniaSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaZapisDokumentuAnulowaniaSkierowaniaResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaZapisDokumentuAnulowaniaSkierowaniaResult>({
    url: paths.zapisDokumentuAnulowaniaSkierowania,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface ZapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZParams {
  ePuapToken?: string;
  zwrocXml?: boolean;
}

function zapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZ(args: { params: ZapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZParams; data: ReceptyZapisDokumentuAnulowaniaSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaZapisDokumentuAnulowaniaSkierowaniaResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaZapisDokumentuAnulowaniaSkierowaniaResult>({
    url: paths.zapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface WeryfikacjaDokumentuSkierowaniaParams {
  zwrocXml?: boolean;
  operationSecondsTimeout?: number;
}

function weryfikacjaDokumentuSkierowania(args: { params: WeryfikacjaDokumentuSkierowaniaParams; data: ReceptyZapisDokumentuSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaWeryfikacjaDokumentuSkierowaniaResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaWeryfikacjaDokumentuSkierowaniaResult>({
    url: paths.weryfikacjaDokumentuSkierowania,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function wyszukanieSkierowanWystawiajacego(data: ReceptyWyszukanieSkierowanWystawiajacegoDaneZapytanieZKontekstemP1): Promise<SkierowaniaWyszukanieSkierowanWystawiajacegoResult> {
  return axios.request<SkierowaniaWyszukanieSkierowanWystawiajacegoResult>({
    url: paths.wyszukanieSkierowanWystawiajacego,
    method: 'post',
    data,
  }).then(res => res.data);
}

function wyszukanieSkierowanUslugobiorcy(data: ReceptyWyszukanieSkierowanUslugobiorcyDaneZapytanieZKontekstemP1): Promise<SkierowaniaWyszukanieSkierowanUslugobiorcyResult> {
  return axios.request<SkierowaniaWyszukanieSkierowanUslugobiorcyResult>({
    url: paths.wyszukanieSkierowanUslugobiorcy,
    method: 'post',
    data,
  }).then(res => res.data);
}

function odczytDanychDostepowychSkierowania(data: ReceptyOdczytDanychDostepowychSkierowaniaDaneZapytanieZKontekstemP1): Promise<SkierowaniaOdczytDanychDostepowychSkierowaniaResult> {
  return axios.request<SkierowaniaOdczytDanychDostepowychSkierowaniaResult>({
    url: paths.odczytDanychDostepowychSkierowania,
    method: 'post',
    data,
  }).then(res => res.data);
}

function wydrukSkierowaniaPdf(data: ReceptyOdczytDokumentuSkierowaniaDaneZapytanieZKontekstemP1): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukSkierowaniaPdf,
    method: 'post',
    responseType: 'blob',
    data,
  }).then(convertBlobResponseToFile);
}

interface WydrukSkierowaniaLokalnegoPdfParams {
  kod?: string;
  klucz?: string;
}

function wydrukSkierowaniaLokalnegoPdf(params: WydrukSkierowaniaLokalnegoPdfParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukSkierowaniaLokalnegoPdf,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

function wydrukSkierowaniaHtml(data: ReceptyOdczytDokumentuSkierowaniaDaneZapytanieZKontekstemP1): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukSkierowaniaHtml,
    method: 'post',
    responseType: 'blob',
    data,
  }).then(convertBlobResponseToFile);
}

interface WydrukSkierowaniaWystawionegoHtmlParams {
  idSkierowania?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
}

function wydrukSkierowaniaWystawionegoHtml(params: WydrukSkierowaniaWystawionegoHtmlParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukSkierowaniaWystawionegoHtml,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

interface WydrukInformacjiOSkierowaniuWystawionymHtmlParams {
  idSkierowania?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
  wydrukRozszezony?: boolean;
}

function wydrukInformacjiOSkierowaniuWystawionymHtml(params: WydrukInformacjiOSkierowaniuWystawionymHtmlParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukInformacjiOSkierowaniuWystawionymHtml,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

interface WydrukInformacjiOSkierowaniuWystawionymPdfParams {
  idSkierowania?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
  wydrukRozszezony?: boolean;
}

function wydrukInformacjiOSkierowaniuWystawionymPdf(params: WydrukInformacjiOSkierowaniuWystawionymPdfParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukInformacjiOSkierowaniuWystawionymPdf,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

interface WystawioneSkierowaniaParams {
  kodSystemu?: string;
  zewnetrzneIds?: number[];
  zwrocXml?: boolean;
}

function wystawioneSkierowania(params: WystawioneSkierowaniaParams): Promise<SkierowaniaSkierowanieWystawione[]> {
  return axios.request<SkierowaniaSkierowanieWystawione[]>({
    url: paths.wystawioneSkierowania,
    method: 'get',
    params,
  }).then(res => res.data);
}

interface SkierowanieLokalneParams {
  kod?: string;
  klucz?: string;
  zwrocInformacjeOSkierowaniuHtml?: boolean;
  zwrocWydrukSkierowaniaHtml?: boolean;
}

function skierowanieLokalne(params: SkierowanieLokalneParams): Promise<SkierowaniaSkierowanieLokalneDto> {
  return axios.request<SkierowaniaSkierowanieLokalneDto>({
    url: paths.skierowanieLokalne,
    method: 'get',
    params,
  }).then(res => res.data);
}

interface ZapiszSkierowaniePodpisanePZParams {
  ePuapToken?: string;
  zwrocXml?: boolean;
}

function zapiszSkierowaniePodpisanePZ(args: { params: ZapiszSkierowaniePodpisanePZParams; data: ReceptyZapisDokumentuSkierowaniaDaneZapytanieZKontekstemP1; }): Promise<SkierowaniaZapisDokumentuSkierowaniaResult> {
  const { params, data } = args;
  return axios.request<SkierowaniaZapisDokumentuSkierowaniaResult>({
    url: paths.zapiszSkierowaniePodpisanePZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function convertBlobResponseToFile(res: AxiosResponse<Blob>): File {
  const contentDispositionHeader = res.headers['content-disposition'] || '';
  const filenameMatch = /filename="?([^;,"]+)/.exec(contentDispositionHeader);
  const filename = filenameMatch ? filenameMatch[1] : '';
  return new File([res.data], filename);
}

export const skierowaniaService = {
  paths,
  odczytDokumentuSkierowania,
  odczytDokumentuSkierowaniaDoRealizacji,
  przyjecieDoRealizacjiSkierowania,
  odmowaPrzyjeciaDoRealizacjiSkierowania,
  rezygnacjaZRealizacjiSkierowania,
  przekazanieInformacjiOBrakachDokumentacjiSkierowania,
  zakonczenieRealizacjiSkierowania,
  zapisDokumentuSkierowania,
  wyslijSkierowanieDoPodpisuPZ,
  wyslijAnulowanieSkierowanieDoPodpisuPZ,
  zapisDokumentuAnulowaniaSkierowania,
  zapisDokumentuAnulowaniaSkierowaniaPodpisanegoPZ,
  weryfikacjaDokumentuSkierowania,
  wyszukanieSkierowanWystawiajacego,
  wyszukanieSkierowanUslugobiorcy,
  odczytDanychDostepowychSkierowania,
  wydrukSkierowaniaPdf,
  wydrukSkierowaniaLokalnegoPdf,
  wydrukSkierowaniaHtml,
  wydrukSkierowaniaWystawionegoHtml,
  wydrukInformacjiOSkierowaniuWystawionymHtml,
  wydrukInformacjiOSkierowaniuWystawionymPdf,
  wystawioneSkierowania,
  skierowanieLokalne,
  zapiszSkierowaniePodpisanePZ,
};
