import { Button, Form, formatDate, FormItem, Panel, PanelBody, PanelHeader } from "@optimed/react";
import React from "react";
import { ZmListaZdarzenMedycznychResponseItem } from "../../../api/models";

interface EncounterQueryP1ResultItemProps {
  item: ZmListaZdarzenMedycznychResponseItem;
  onOpenDetails: (x: ZmListaZdarzenMedycznychResponseItem) => any;
  onRemove: (x: ZmListaZdarzenMedycznychResponseItem) => any;
}

export function EncounterQueryP1ResultItem(props: EncounterQueryP1ResultItemProps) {
  const { item, onOpenDetails, onRemove } = props;

  return (
    <Panel>
      <PanelHeader>
        {item.typZdarzenia}, zasób Encounter#{item.idZasobu}
      </PanelHeader>
      <PanelBody>
        <Form layout="vertical">
          <FormItem label="Data zdarzenia">
            {formatDate(item.dataOd, "yyyy-MM-dd HH:mm")}
            {item.dataOd !== item.dataDo && <span> - {formatDate(item.dataDo, "yyyy-MM-dd HH:mm")}</span>}
          </FormItem>

          <FormItem label="Miejsce świadczenia">{item.miejsceSwiadczenia}</FormItem>

          <FormItem label="Epizod OID">
            {item.epizodOid?.root}
            <br />
            {item.epizodOid?.extension}
          </FormItem>

          <FormItem label="Pacjent OID">
            {item.pacjentOid?.root}
            <br />
            {item.pacjentOid?.extension}
          </FormItem>

          <FormItem>
            <Button sm onClick={() => onOpenDetails(item)}>
              Pokaż szczegóły
            </Button>
            <Button sm onClick={() => onRemove(item)}>
              Usuń zdarzenie
            </Button>
          </FormItem>
        </Form>
      </PanelBody>
    </Panel>
  );
}
