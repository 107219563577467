import { Button, Panel, PanelBody } from "@optimed/react";
import React from "react";

interface SyncMissingPrescriptionsKeysProps {}

interface SyncMissingPrescriptionsKeysState {
  status: SyncStatus;
  processed: number;
  ids: number[];
  errors: number;
  stopRequest: boolean;
}

enum SyncStatus {
  NotStared,
  GettingIds,
  Syncing,
  Finished,
}

export class SyncMissingPrescriptionsKeys extends React.Component<
  SyncMissingPrescriptionsKeysProps,
  SyncMissingPrescriptionsKeysState
> {
  constructor(props: SyncMissingPrescriptionsKeysProps) {
    super(props);

    this.syncNext = this.syncNext.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);

    this.state = {
      status: SyncStatus.NotStared,
      errors: 0,
      processed: 0,
      ids: [],
      stopRequest: false,
    };
  }

  render() {
    return (
      <Panel header="Synchronizacja kluczy recept">
        <PanelBody>
          <div>{this.renderInfo()}</div>
          <div>{this.renderButtons()}</div>
        </PanelBody>
      </Panel>
    );
  }

  renderInfo() {
    const { status, processed, errors, ids } = this.state;
    let progress = `Przetworzono ${processed}/${ids.length}`;
    if (errors) progress += ` (błędów: ${errors})`;

    switch (status) {
      case SyncStatus.Finished:
        return <div>{progress}, zakończono</div>;

      case SyncStatus.GettingIds:
        return "Pobieranie identyfikatorów pakietów z receptami bez kluczy";

      case SyncStatus.Syncing:
        return <div>{progress}, trwa synchronizacja</div>;

      case SyncStatus.NotStared:
        return null;
    }
  }

  renderButtons() {
    if (this.state.stopRequest && this.state.status === SyncStatus.Syncing) {
      return <Button disabled>Oczekiwanie na przerwanie...</Button>;
    }

    switch (this.state.status) {
      case SyncStatus.Finished:
        return <Button onClick={this.start}>Wykonaj ponownie</Button>;

      case SyncStatus.GettingIds:
        return null;

      case SyncStatus.Syncing:
        return <Button onClick={this.stop}>Przerwij</Button>;

      case SyncStatus.NotStared:
        return <Button onClick={this.start}>Rozpocznij</Button>;
    }
  }

  async start() {
    const { status } = this.state;
    if (status !== SyncStatus.Finished && status !== SyncStatus.NotStared) return;

    this.setState({
      status: SyncStatus.GettingIds,
      errors: 0,
      processed: 0,
      ids: [],
      stopRequest: false,
    });

    // const ids = await api.znajdzPakietyBezKluczyRecept();
    // this.setState(
    //   {
    //     ids,
    //     status: SyncStatus.Syncing
    //   },
    //   this.syncNext
    // );
  }

  async syncNext() {
    const { processed, stopRequest, ids } = this.state;
    if (processed >= ids.length || stopRequest) {
      this.setState({
        status: SyncStatus.Finished,
      });
      return;
    }

    // const nextId = ids[processed];

    try {
      // await api.synchronizacjaKluczyReceptWPakiecie(nextId);
      // this.setState(
      //   s => ({
      //     processed: s.processed + 1
      //   }),
      //   this.syncNext
      // );
    } catch (e) {
      console.error(e);
      this.setState(s => ({ errors: s.errors + 1, processed: processed + 1 }), this.syncNext);
    }
  }

  stop() {
    this.setState({
      stopRequest: true,
    });
  }
}
