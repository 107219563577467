import {
  Button,
  LoadingRegion,
  Panel,
  PanelBody,
  Text,
  formatDate,
  useDataLoaderWithPendingOperation,
} from "@optimed/react";
import { zgodyPacjentaService } from "api";
import {
  ZgodyPacjentaKontekstPracownikaMedycznego,
  ZgodyPacjentaRolaPracownikaMedycznego,
  ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteriaZgodaPacjentaRequest,
} from "api/models";
import { useState } from "react";

interface OdczytZgodyPacjentaNaDostepDoDokumentacjiProps {
  context: ZgodyPacjentaKontekstPracownikaMedycznego;
  peselPacjenta: string;
}

export function OdczytZgodyPacjentaNaDostepDoDokumentacji(props: OdczytZgodyPacjentaNaDostepDoDokumentacjiProps) {
  const { context, peselPacjenta } = props;
  const [request, setRequest] =
    useState<ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteriaZgodaPacjentaRequest>();
  const [response, op] = useDataLoaderWithPendingOperation(
    async () =>
      request ? zgodyPacjentaService.zgodaNaDostepDoDokumentacjiPacjentaPrzezPracownikaPodmiot(request) : undefined,
    [request]
  );

  const execute = () => {
    setRequest({
      kontekst: context,
      payload: {
        daneWeryfikowanegoPodmiotu: {
          typSwiadczeniodawcy: context.typSwiadczeniodawcy,
          kodResortowyI: context.kodResortowyI,
          kodResortowyV: context.kodResortowyV,
          kodResortowyVII: context.kodResortowyVII,
          miejsceIzbyLekarskiej: context.miejsceIzbyLekarskiej,
        },
        daneWeryfikowanegoPracownika: {
          rolaPracownikaMedycznego: context.rolaPracownikaMedycznego || ZgodyPacjentaRolaPracownikaMedycznego.Lekarz,
          identyfikatorPracownika: context.identyfikatorPracownika,
        },
        peselPacjenta: peselPacjenta,
        waznoscNaDataCzas: formatDate(new Date(), "ISO8601"),
      },
    });
  };

  const clear = () => {
    setRequest(undefined);
  };

  return (
    <Panel header="Odczyt zgody pacjenta na dostęp do dokumentacji przez pracownika/podmiot">
      <PanelBody>
        <Button onClick={execute} disabled={op.state.pending}>
          Wykonaj zapytanie
        </Button>
        <Button onClick={clear}>Wyczyść</Button>
        {request && (
          <>
            <Text block bold>
              Zapytanie:
            </Text>
            <Text block style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(request, undefined, 2)}
            </Text>
          </>
        )}
        <LoadingRegion promise={op.promise}>
          {response && (
            <>
              <Text block bold>
                Odpowiedź:
              </Text>
              <Text block style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(response, undefined, 2)}
              </Text>
            </>
          )}
        </LoadingRegion>
      </PanelBody>
    </Panel>
  );
}
