import React from "react";
import { ReceptyWeryfikacjaPakietuReceptResult } from "../../../api/models";

interface WeryfikacjaReceptResultViewerProps {
  result: ReceptyWeryfikacjaPakietuReceptResult;
}

export class WeryfikacjaReceptResultViewer extends React.PureComponent<WeryfikacjaReceptResultViewerProps> {
  render() {
    return <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(this.props.result, undefined, 2)}</pre>;
  }
}
