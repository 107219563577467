// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ServerEnvironmentInfoDto, CertCacheEntryDto, P1CommunicationContextCacheEntryDto } from './models';

const paths = {
  heartbeat: 'api/Heartbeat',
  appInfo: 'api/AppInfo',
  commonCertCache: 'api/Common/CertCache',
  commonP1CommunicationContextCache: 'api/Common/P1CommunicationContextCache',
};

function heartbeat(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.heartbeat,
    method: 'get',
  });
}

function appInfo(): Promise<ServerEnvironmentInfoDto> {
  return axios.request<ServerEnvironmentInfoDto>({
    url: paths.appInfo,
    method: 'get',
  }).then(res => res.data);
}

function commonCertCache(): Promise<CertCacheEntryDto[]> {
  return axios.request<CertCacheEntryDto[]>({
    url: paths.commonCertCache,
    method: 'get',
  }).then(res => res.data);
}

function commonP1CommunicationContextCache(): Promise<P1CommunicationContextCacheEntryDto[]> {
  return axios.request<P1CommunicationContextCacheEntryDto[]>({
    url: paths.commonP1CommunicationContextCache,
    method: 'get',
  }).then(res => res.data);
}

export const environmentService = {
  paths,
  heartbeat,
  appInfo,
  commonCertCache,
  commonP1CommunicationContextCache,
};
