import {
  Button,
  DialogContent,
  DialogProps,
  Grid,
  GridItem,
  LoadingSpinner,
  makeDialogOpener,
  useDialogSize,
} from "@optimed/react";
import { useEffect } from "react";

interface ProgressPendingDialogProps extends DialogProps<void> {
  promise: Promise<any>;
  pendingTxt?: string;
  dialogHeader?: string;
}

export function ProgressPendingDialog(props: ProgressPendingDialogProps) {
  useDialogSize("400px");
  const { dialog, promise, pendingTxt, dialogHeader } = props;

  useEffect(() => {
    promise.finally(() => dialog.close());
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogContent header={dialogHeader || "Proszę czekać"}>
      <Grid gap>
        <GridItem rowTemplate="1fr" contentAlign="center">
          {pendingTxt || "Operacja w trakcie..."}
          &nbsp;
          <LoadingSpinner />
        </GridItem>
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={dialog.cancel}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}

export const progressPendingDialog = makeDialogOpener(ProgressPendingDialog);
