import { Badge, Button, dialogManager, formatDate, Panel, PanelBody, PanelHeader, PullRight } from "@optimed/react";
import React from "react";
import { ReceptyWynikWyszukiwaniaLokalnychReceptItem } from "../../../api/models";
import { StatusPakietuLabel } from "../StatusPakietuLabel";
import { PrescriptionPacketPrintDialog } from "./PrescriptionPacketPrintDialog";
import { PrescriptionPrintDialog } from "./PrescriptionPrintDialog";
import { PrescriptionValidationMessagesDialog } from "./PrescriptionValidationMessagesDialog";
import { PrescriptionXmlDialog } from "./PrescriptionXmlDialog";

interface FindPrescriptionsResultItemProps {
  recepta: ReceptyWynikWyszukiwaniaLokalnychReceptItem;
}

interface FindPrescriptionsResultItemState {}

export class FindPrescriptionsResultItem extends React.PureComponent<
  FindPrescriptionsResultItemProps,
  FindPrescriptionsResultItemState
> {
  constructor(props: FindPrescriptionsResultItemProps) {
    super(props);

    this.showXml = this.showXml.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.print = this.print.bind(this);
    this.printPacket = this.printPacket.bind(this);

    this.state = {};
  }

  render() {
    const x = this.props.recepta;

    return (
      <Panel>
        <PanelHeader>
          {formatDate(x.dataDodania, "yyyy-MM-dd HH:mm:ss")}
          <PullRight>
            {x.anulowana && <Badge type="warning">Anulowana</Badge>}
            &nbsp;
            {<StatusPakietuLabel status={x.statusPakietu} />}
          </PullRight>
        </PanelHeader>
        <PanelBody>
          <div>Kod pakietu: {x.kodPakietu}</div>
          <div>Klucz pakietu: {x.kluczPakietu}</div>
          <div>Klucz recepty: {x.kluczRecepty}</div>
          <div>Id recepty: {x.id}</div>
          <div>Id pakietu: {x.idPakietu}</div>
          {x.identyfikatorZewnetrznyRecepty && (
            <div>
              Id zew. recepty: {x.identyfikatorZewnetrznyRecepty.system}_{x.identyfikatorZewnetrznyRecepty.id}
            </div>
          )}
          {x.identyfikatorZewnetrznyPakietu && (
            <div>
              Id zew. pakietu: {x.identyfikatorZewnetrznyPakietu.system}_{x.identyfikatorZewnetrznyPakietu.id}
            </div>
          )}
          <div>
            <Button sm onClick={this.showXml}>
              XML
            </Button>
            &nbsp;
            <Button sm onClick={this.showDetails}>
              Szczegóły
            </Button>
            &nbsp;
            <Button sm onClick={this.print}>
              Wydruk recepty
            </Button>
            &nbsp;
            <Button sm onClick={this.printPacket}>
              Wydruk pakietu recept
            </Button>
          </div>
        </PanelBody>
      </Panel>
    );
  }

  showXml() {
    dialogManager.open(PrescriptionXmlDialog, {
      idRecepty: this.props.recepta.id!,
    });
  }

  showDetails() {
    dialogManager.open(PrescriptionValidationMessagesDialog, {
      idRecepty: this.props.recepta.id!,
    });
  }

  print() {
    dialogManager.open(PrescriptionPrintDialog, {
      idRecepty: this.props.recepta.id!,
    });
  }

  printPacket() {
    dialogManager.open(PrescriptionPacketPrintDialog, {
      idPakietu: this.props.recepta.idPakietu!,
    });
  }
}
