import "@optimed/react";
import { LoadingRegion } from "@optimed/react";
import "@optimed/react/build/dist/optimed-react.css";
import "@optimed/react/build/font/roboto.scss";
import { createRoot } from "react-dom/client";
import { App } from "./core/App";
import { loadingRegionDefaultRenderer } from "./core/errorHandling/GenericErrorFeedback";
import "./index.scss";

LoadingRegion.DefaultError = loadingRegionDefaultRenderer;

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
