// File generated automatically, do not modify

import axios from 'axios';
import { ReceptyDaneWyszukiwaniaLokalnychRecept, ReceptyWynikWyszukiwaniaLokalnychRecept } from './models';

const paths = {
  wyszukajWystawioneRecepty: 'api/Recepty/Maintenance/WyszukajWystawioneRecepty',
};

function wyszukajWystawioneRecepty(data: ReceptyDaneWyszukiwaniaLokalnychRecept): Promise<ReceptyWynikWyszukiwaniaLokalnychRecept> {
  return axios.request<ReceptyWynikWyszukiwaniaLokalnychRecept>({
    url: paths.wyszukajWystawioneRecepty,
    method: 'post',
    data,
  }).then(res => res.data);
}

export const receptyMaintenanceService = {
  paths,
  wyszukajWystawioneRecepty,
};
