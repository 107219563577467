import { TextArea } from "@optimed/react";
import React from "react";
import "./XmlInput.scss";

interface XmlInputProps {
  value: string;
  onChange: (val: string) => any;
}

export function XmlInput(props: XmlInputProps) {
  const { value, onChange } = props;
  return <TextArea value={value} onChange={onChange} className="xml-input" placeholder="Zawartość XML..." />;
}
