import {
  Button,
  clipboardUtils,
  DialogContent,
  DialogProps,
  Grid,
  GridItem,
  makeDialogOpener,
  useDialogSize,
} from "@optimed/react";
import React from "react";
import { ZmZdarzenieMedyczneDaneZp1SuroweDaneZasobu } from "../../../api/models";

interface EncounterQueryP1RawResourceDialogProps extends DialogProps<void> {
  resource: ZmZdarzenieMedyczneDaneZp1SuroweDaneZasobu;
}

export function EncounterQueryP1RawResourceDialog(props: EncounterQueryP1RawResourceDialogProps) {
  useDialogSize("900px", "700px");
  const { dialog, resource } = props;
  const header = `${resource.typZasobu}#${resource.idZasobu}`;

  return (
    <DialogContent header={header}>
      <Grid gap>
        <GridItem rowTemplate="1fr" scrollable>
          <pre>{resource.xml}</pre>
        </GridItem>

        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={() => clipboardUtils.copyTextToClipboard(resource.xml || "")}>Kopiuj do schowka</Button>
          <Button onClick={dialog.cancel}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}

export const encounterQueryP1RawResourceDialog = makeDialogOpener(EncounterQueryP1RawResourceDialog);
