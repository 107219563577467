// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ZmListaZdarzenMedycznychCriteriaZmRequest, ZmListaZdarzenMedycznychResponse, ZmListaNaglowkowDokumentowCriteriaZmRequest, ZmListaNaglowkowDokumentowResponse, ZmZdarzenieMedyczneZmRequest, ZmZdarzenieMedyczneWynikPrzeslania, ZmZdarzenieMedyczneUsunZmRequest, ZmDaneDoPodpisuZasobuPrzezPracownikaZmRequest, ZmPodpisZasobuPrzezPracownikaResponse, ZmStringZmRequest, ZmZdarzenieMedyczneDaneZp1, ZmPacjentZmRequest, Oid, ZmListaZasobowDoPodpisuPracownikaCriteria, ZmListaZasobowDoPodpisuPracownika, ZmIdentyfikatorZasobuDoPodpisuPrzezPracownika } from './models';

const paths = {
  zmListaZdarzenMedycznych: 'api/zm/ListaZdarzenMedycznych',
  zmListaNaglowkowDokumentow: 'api/zm/ListaNaglowkowDokumentow',
  zmPrzeslijZdarzenieMedyczne: 'api/zm/PrzeslijZdarzenieMedyczne',
  zmUsunZdarzenieMedyczne: 'api/zm/UsunZdarzenieMedyczne',
  zmPodpiszZasobCertyfikatemPracownika: 'api/zm/PodpiszZasobCertyfikatemPracownika',
  zmPobierzDaneZdarzenia: 'api/zm/PobierzDaneZdarzenia',
  zmGenerujOidPacjenta: 'api/zm/GenerujOidPacjenta',
  zmListaZasobowDoPodpisuPracownika: 'api/zm/ListaZasobowDoPodpisuPracownika',
  zmOznaczZasobJakoPozostawionyBezPodpisu: 'api/zm/OznaczZasobJakoPozostawionyBezPodpisu',
};

function zmListaZdarzenMedycznych(data: ZmListaZdarzenMedycznychCriteriaZmRequest): Promise<ZmListaZdarzenMedycznychResponse> {
  return axios.request<ZmListaZdarzenMedycznychResponse>({
    url: paths.zmListaZdarzenMedycznych,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmListaNaglowkowDokumentow(data: ZmListaNaglowkowDokumentowCriteriaZmRequest): Promise<ZmListaNaglowkowDokumentowResponse> {
  return axios.request<ZmListaNaglowkowDokumentowResponse>({
    url: paths.zmListaNaglowkowDokumentow,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmPrzeslijZdarzenieMedyczne(data: ZmZdarzenieMedyczneZmRequest): Promise<ZmZdarzenieMedyczneWynikPrzeslania> {
  return axios.request<ZmZdarzenieMedyczneWynikPrzeslania>({
    url: paths.zmPrzeslijZdarzenieMedyczne,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmUsunZdarzenieMedyczne(data: ZmZdarzenieMedyczneUsunZmRequest): Promise<ZmZdarzenieMedyczneWynikPrzeslania> {
  return axios.request<ZmZdarzenieMedyczneWynikPrzeslania>({
    url: paths.zmUsunZdarzenieMedyczne,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmPodpiszZasobCertyfikatemPracownika(data: ZmDaneDoPodpisuZasobuPrzezPracownikaZmRequest): Promise<ZmPodpisZasobuPrzezPracownikaResponse> {
  return axios.request<ZmPodpisZasobuPrzezPracownikaResponse>({
    url: paths.zmPodpiszZasobCertyfikatemPracownika,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmPobierzDaneZdarzenia(data: ZmStringZmRequest): Promise<ZmZdarzenieMedyczneDaneZp1> {
  return axios.request<ZmZdarzenieMedyczneDaneZp1>({
    url: paths.zmPobierzDaneZdarzenia,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmGenerujOidPacjenta(data: ZmPacjentZmRequest): Promise<Oid> {
  return axios.request<Oid>({
    url: paths.zmGenerujOidPacjenta,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmListaZasobowDoPodpisuPracownika(data: ZmListaZasobowDoPodpisuPracownikaCriteria): Promise<ZmListaZasobowDoPodpisuPracownika> {
  return axios.request<ZmListaZasobowDoPodpisuPracownika>({
    url: paths.zmListaZasobowDoPodpisuPracownika,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zmOznaczZasobJakoPozostawionyBezPodpisu(data: ZmIdentyfikatorZasobuDoPodpisuPrzezPracownika): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.zmOznaczZasobJakoPozostawionyBezPodpisu,
    method: 'post',
    data,
  });
}

export const zdarzeniaMedyczneService = {
  paths,
  zmListaZdarzenMedycznych,
  zmListaNaglowkowDokumentow,
  zmPrzeslijZdarzenieMedyczne,
  zmUsunZdarzenieMedyczne,
  zmPodpiszZasobCertyfikatemPracownika,
  zmPobierzDaneZdarzenia,
  zmGenerujOidPacjenta,
  zmListaZasobowDoPodpisuPracownika,
  zmOznaczZasobJakoPozostawionyBezPodpisu,
};
