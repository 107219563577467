import { Alert, Button, Form, FormItem, Grid, GridItem, Panel, PanelBody, TextInput } from "@optimed/react";
import { useState } from "react";
import { ReceptyKontekstWywolaniaP1, TypSwiadczeniodawcy, ZawodMedyczny } from "../../../api/models";
import { ReceptaContextForm } from "../../recepty";
import { skierowanieOdczytDialog } from "./SkierowanieOdczytDialog";

export function SkierowanieOdczytPage() {
  const [p1Ctx, setP1Ctx] = useState<ReceptyKontekstWywolaniaP1>({
    typSwiadczeniodawcy: TypSwiadczeniodawcy.PodmiotLeczniczy,
    npwz: "1091231",
    numerKontaUslugodawcy: "51",
    kodResortowyI: "000000814987",
    zawodMedyczny: ZawodMedyczny.Lekarz,
  });
  const [kluczSkierowania, setKluczSkierowania] = useState("");
  const [kodSkierowania, setKodSkierowania] = useState("");
  const [rootSkierowania, setRootSkierowania] = useState("");
  const [extensionSkierowania, setExtensionSkierowania] = useState("");

  const readByKeyAvailable = kluczSkierowania && kodSkierowania && p1Ctx.kodResortowyVII;
  const readByOidAvailable = rootSkierowania && extensionSkierowania && p1Ctx.kodResortowyVII;

  const readByKey = () => {
    if (!readByKeyAvailable) return;

    skierowanieOdczytDialog.open({
      p1Ctx,
      poKluczu: {
        kluczSkierowania,
        kodSkierowania,
      },
    });
  };

  const readByOid = () => {
    if (!readByOidAvailable) return;

    skierowanieOdczytDialog.open({
      p1Ctx,
      poId: {
        root: rootSkierowania,
        extension: extensionSkierowania,
      },
    });
  };

  return (
    <Grid gap>
      <GridItem colTemplate="300px" scrollable>
        <h5>Kontekst komunikacji z P1</h5>
        <ReceptaContextForm value={p1Ctx} onChange={setP1Ctx} />
      </GridItem>
      <GridItem colTemplate="1fr">
        {!p1Ctx.kodResortowyVII && (
          <Alert className="mb-2" type="danger">
            Do poprawnego działania zapytań e-skierowań wymagane jest wprowadzenie kodu resortowego VII w kontekście
            komunikacji
          </Alert>
        )}
        <Panel header="Odczyt skierowania według klucza i kodu (PIN)">
          <PanelBody>
            <Form layout="vertical">
              <FormItem label="Klucz skierowania">
                <TextInput value={kluczSkierowania} onChange={setKluczSkierowania} />
              </FormItem>
              <FormItem label="Kod skierowania (PIN)">
                <TextInput value={kodSkierowania} onChange={setKodSkierowania} />
              </FormItem>
            </Form>
            <Button onClick={readByKey} disabled={!readByKeyAvailable}>
              Odczytaj
            </Button>
          </PanelBody>
        </Panel>
        <Panel header="Odczyt skierowania według identyfikatora (root + extension)">
          <PanelBody>
            <Form layout="vertical">
              <FormItem label="Root">
                <TextInput value={rootSkierowania} onChange={setRootSkierowania} />
              </FormItem>
              <FormItem label="Extension">
                <TextInput value={extensionSkierowania} onChange={setExtensionSkierowania} />
              </FormItem>
            </Form>
            <Button onClick={readByOid} disabled={!readByOidAvailable}>
              Odczytaj
            </Button>
          </PanelBody>
        </Panel>
      </GridItem>
    </Grid>
  );
}
