import {
  Grid,
  GridItem,
  LoadingRegion,
  toast,
  useDataLoaderWithPendingOperation,
  useRefAutoUpdate,
  useToastValidationFeedback,
} from "@optimed/react";
import { useState } from "react";
import { zdarzeniaMedyczneService } from "../../../api";
import {
  KontekstZapytaniaZm,
  RodzajIdentyfikatoraOsoby,
  TypSwiadczeniodawcy,
  ZmListaZdarzenMedycznychCriteria,
  ZmListaZdarzenMedycznychResponseItem,
  ZmRolaUzytkownika,
} from "../../../api/models";
import { deleteConfirmDialog } from "../../../core/generalDialogs/DeleteConfirmDialog";
import { progressPendingDialog } from "../../../core/generalDialogs/ProgressPendingDialog";
import { ZmContextForm } from "../ZmContextForm";
import { EncounterQueryP1Criteria } from "./EncounterQueryP1Criteria";
import { encounterQueryP1DetailsDialog } from "./EncounterQueryP1DetailsDialog";
import { EncounterQueryP1IdSelector } from "./EncounterQueryP1IdSelector";
import { EncounterQueryP1Result } from "./EncounterQueryP1Result";

export function EncounterQueryP1Page() {
  const [criteria, setCriteria] = useState<ZmListaZdarzenMedycznychCriteria>({
    rodzajIdentyfikatoraPacjenta: RodzajIdentyfikatoraOsoby.Pesel,
    sortujOdNajnowszych: true,
    limitLiczbyZdarzen: 50,
  });
  const [zmContext, setZmContext] = useState<KontekstZapytaniaZm>({
    typSwiadczeniodawcy: TypSwiadczeniodawcy.PodmiotLeczniczy,
    identyfikatorUzytkownika: "1091231",
    numerKontaUslugodawcy: "51",
    kodResortowyI: "000000814987",
    rolaUzytkownika: ZmRolaUzytkownika.Lekarz,
  });
  const zmContextRef = useRefAutoUpdate(zmContext);
  const [submittedCriteria, setSubmittedCriteria] = useState<ZmListaZdarzenMedycznychCriteria>();
  const [result, op] = useDataLoaderWithPendingOperation(
    () =>
      submittedCriteria
        ? zdarzeniaMedyczneService.zmListaZdarzenMedycznych({
            kontekst: zmContextRef.current,
            payload: submittedCriteria,
          })
        : Promise.resolve(undefined),
    [submittedCriteria]
  );
  useToastValidationFeedback(op.state.validationResult);

  const submit = () => {
    const c = { ...criteria };
    if (c.limitLiczbyZdarzen === undefined) {
      c.limitLiczbyZdarzen = null as any;
    }

    setSubmittedCriteria(c);
  };

  const openDetails = (encounterId: string) => {
    encounterQueryP1DetailsDialog.open({
      zmContext,
      idZasobu: encounterId,
    });
  };

  const remove = (item: ZmListaZdarzenMedycznychResponseItem) => {
    deleteConfirmDialog
      .open({
        additionalAgreeCheckbox: true,
        question:
          "Czy na pewno chcesz usunąć zdarzenie medyczne? Operacja jest nieodwracalna, spowoduje usunięcie wszystkich zależnych obiektów od zdarzenia medycznego (rozpoznań, procedur, obserwacji) i samego zdarzenia.",
      })
      .onResult(() => {
        const promise = zdarzeniaMedyczneService
          .zmUsunZdarzenieMedyczne({
            kontekst: zmContextRef.current,
            payload: { idZasobuStr: item.idZasobu },
          })
          .then(res => {
            if (!res.komunikaty || !res.komunikaty.length) {
              toast.success("Pomyślnie usunięto zdarzenie medyczne");
            } else {
              toast.error(
                "Błąd usuwania zdarzenia",
                <ul>
                  {res.komunikaty.map((x, i) => (
                    <li key={i}>{x.komunikat}</li>
                  ))}
                </ul>
              );
            }
          })
          .catch(err => {
            toast.error("Błąd usuwania zdarzenia");
          });

        progressPendingDialog.open({
          promise,
          pendingTxt: "Trwa usuwania zdarzenia medycznego...",
        });
      });
  };

  return (
    <Grid gap>
      <GridItem colTemplate="300px" scrollable>
        <h5>Kontekst komunikacji z P1</h5>
        <ZmContextForm value={zmContext} onChange={setZmContext} />
        <h5>Zdarzenie po id</h5>
        <EncounterQueryP1IdSelector onShowEncounterDetails={openDetails} />
        <h5>Filtry</h5>
        <EncounterQueryP1Criteria
          criteria={criteria}
          onChange={setCriteria}
          onSubmit={submit}
          validation={op.state.validationResult}
        />
        {result && (
          <div>
            Wszystkich wyników: {result.total}, pobrano {result.items?.length}
          </div>
        )}
      </GridItem>
      <GridItem colTemplate="1fr" scrollable>
        {op.state.error ? (
          <div>
            <h3>Błąd wykonania operacji</h3>
            {op.state.errorMessage}
          </div>
        ) : (
          <LoadingRegion promise={op.promise}>
            <EncounterQueryP1Result result={result} onOpenDetails={x => openDetails(x.idZasobu!)} onRemove={remove} />
          </LoadingRegion>
        )}
      </GridItem>
    </Grid>
  );
}
