import {
  Button,
  DialogContent,
  DialogProps,
  Grid,
  GridItem,
  LoadingRegion,
  useDataLoader,
  useDialogSize,
} from "@optimed/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { receptyService } from "../../../api";
import { routes } from "../../routing/routes";

interface PrescriptionXmlDialogProps extends DialogProps<void> {
  idRecepty: number;
}

export function PrescriptionXmlDialog(props: PrescriptionXmlDialogProps) {
  const { idRecepty, dialog } = props;

  useDialogSize("95%", "95%");
  const navigate = useNavigate();
  const [, promise] = useDataLoader(() =>
    receptyService.daneWystawionejRecepty({ idRecepty, zwrocXml: true }).then(res => {
      setXml(res.xml || "Brak danych");
      return res;
    })
  );
  const [xml, setXml] = useState("");
  const manualSendXml = useCallback(
    (xml: string) => {
      localStorage.setItem(
        "manualxmldata",
        JSON.stringify({
          p1Context: {},
          xml,
        })
      );
      navigate(routes.prescriptions.manualXml);
      dialog.close();
    },
    [navigate, dialog]
  );

  return (
    <DialogContent header="Podgląd XML recepty">
      <Grid gap>
        <GridItem rowTemplate="1fr">
          <LoadingRegion promise={promise}>
            <textarea style={{ width: "100%", height: "100%" }}>{xml}</textarea>
          </LoadingRegion>
        </GridItem>
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={() => manualSendXml(xml)}>Wykorzystaj w ręcznej walidacji XML</Button>
          <Button onClick={() => dialog.close()}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}
