// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ZmListaZdarzenMedycznychCriteriaZmRequest, ZmListaNaglowkowDokumentowCriteriaZmRequest, ZmProvenanceCriteriaZmRequest, ZmPacjentZmRequest, ZmInt64ZmRequest, ZmStringZmRequest, ZdarzeniaMedyczneTestControllerRawFhirResponse } from './models';

const paths = {
  zmTestListaZdarzenMedycznych: 'api/zm/test/ListaZdarzenMedycznych',
  zmTestListaNaglowkowDokumentow: 'api/zm/test/ListaNaglowkowDokumentow',
  zmTestListaProvenance: 'api/zm/test/ListaProvenance',
  zmTestWyszukajPacjenta: 'api/zm/test/WyszukajPacjenta',
  zmTestPobierzPacjentaPoIdZasobu: 'api/zm/test/PobierzPacjentaPoIdZasobu',
  zmTestRawFhirGet: 'api/zm/test/RawFhirGet',
};

function zmTestListaZdarzenMedycznych(data: ZmListaZdarzenMedycznychCriteriaZmRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.zmTestListaZdarzenMedycznych,
    method: 'post',
    data,
  });
}

function zmTestListaNaglowkowDokumentow(data: ZmListaNaglowkowDokumentowCriteriaZmRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.zmTestListaNaglowkowDokumentow,
    method: 'post',
    data,
  });
}

function zmTestListaProvenance(data: ZmProvenanceCriteriaZmRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.zmTestListaProvenance,
    method: 'post',
    data,
  });
}

function zmTestWyszukajPacjenta(data: ZmPacjentZmRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.zmTestWyszukajPacjenta,
    method: 'post',
    data,
  });
}

function zmTestPobierzPacjentaPoIdZasobu(data: ZmInt64ZmRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.zmTestPobierzPacjentaPoIdZasobu,
    method: 'post',
    data,
  });
}

function zmTestRawFhirGet(data: ZmStringZmRequest): Promise<ZdarzeniaMedyczneTestControllerRawFhirResponse> {
  return axios.request<ZdarzeniaMedyczneTestControllerRawFhirResponse>({
    url: paths.zmTestRawFhirGet,
    method: 'post',
    data,
  }).then(res => res.data);
}

export const zdarzeniaMedyczneTestService = {
  paths,
  zmTestListaZdarzenMedycznych,
  zmTestListaNaglowkowDokumentow,
  zmTestListaProvenance,
  zmTestWyszukajPacjenta,
  zmTestPobierzPacjentaPoIdZasobu,
  zmTestRawFhirGet,
};
