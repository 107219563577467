import { Form, FormItem, Grid, GridItem, TextInput, useLocalStorage } from "@optimed/react";
import {
  TypSwiadczeniodawcy,
  ZgodyPacjentaKontekstPracownikaMedycznego,
  ZgodyPacjentaRolaPracownikaMedycznego,
} from "api/models";
import { useState } from "react";
import { ZgodyContextForm } from "../ZgodyContextForm";
import { OdczytKanalowKomunikacji } from "./OdczytKanalowKomunikacji";
import { OdczytZgodyPacjentaNaDostepDoDokumentacji } from "./OdczytZgodyPacjentaNaDostepDoDokumentacji";

export function ZgodyPacjentaOdczytPage() {
  const [context, setContext] = useState<ZgodyPacjentaKontekstPracownikaMedycznego>({
    typSwiadczeniodawcy: TypSwiadczeniodawcy.PodmiotLeczniczy,
    identyfikatorPracownika: "1091231",
    kodResortowyI: "000000814987",
    kodResortowyVII: "001",
    rolaPracownikaMedycznego: ZgodyPacjentaRolaPracownikaMedycznego.Lekarz,
  });
  const [pacjentPesel, setPacjentPesel] = useLocalStorage("pacjentPesel", "");

  return (
    <Grid gap>
      <GridItem colTemplate="300px" scrollable>
        <h5>Kontekst komunikacji z P1</h5>
        <ZgodyContextForm value={context} onChange={setContext} />
        <h5>Dodatkowe parametry</h5>
        <Form layout="vertical">
          <FormItem label="Pesel pacjenta">
            <TextInput value={pacjentPesel} onChange={setPacjentPesel} />
          </FormItem>
        </Form>
      </GridItem>
      <GridItem colTemplate="1fr" scrollable>
        <OdczytKanalowKomunikacji context={context} peselPacjenta={pacjentPesel} />
        <OdczytZgodyPacjentaNaDostepDoDokumentacji context={context} peselPacjenta={pacjentPesel} />
      </GridItem>
    </Grid>
  );
}
