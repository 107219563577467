import { faList, faPen } from "@fortawesome/free-solid-svg-icons";
import {
  ClickableIcon,
  Form,
  FormItem,
  FormNesting,
  SelectField,
  SelectInput,
  TextField,
  enumUtils,
  useDataLoader,
  useLocalStorage,
} from "@optimed/react";
import { environmentService } from "api";
import {
  MiejsceOkregowejIzbyLekarskiej,
  P1CommunicationContextCacheEntryDto,
  TypCertyfikatu,
  TypSwiadczeniodawcy,
  ZgodyPacjentaKontekstPracownikaMedycznego,
  ZgodyPacjentaRolaPracownikaMedycznego,
  ZmRolaUzytkownika,
} from "api/models";
import { useEffect, useMemo, useState } from "react";

interface ZgodyContextFormProps {
  value: ZgodyPacjentaKontekstPracownikaMedycznego;
  onChange: (value: ZgodyPacjentaKontekstPracownikaMedycznego) => any;
}

const userRoles = enumUtils.getNumericValues(ZgodyPacjentaRolaPracownikaMedycznego);
const typySwiadczeniodawcow = enumUtils.getNumericValues(TypSwiadczeniodawcy);
const miejscaIzbyLekarskiej = enumUtils.getNumericValues(MiejsceOkregowejIzbyLekarskiej);

export function ZgodyContextForm(props: ZgodyContextFormProps) {
  const { value, onChange } = props;

  const [typSwiadczeniodawcy, setTypSwiadczeniodawcy] = useLocalStorage(
    "kontekstP1TypSwiadczeniodawcy",
    TypSwiadczeniodawcy.PodmiotLeczniczy
  );
  const [miejsceIzbyLekarskiej, setMiejsceIzbyLekarskiej] = useLocalStorage<MiejsceOkregowejIzbyLekarskiej | undefined>(
    "kontekstP1MiejsceIzbyLekarskiej",
    undefined
  );
  const [kodI, setKodI] = useLocalStorage("kontekstP1KodResortowyI", "");
  const [kodV, setKodV] = useLocalStorage("kontekstP1KodResortowyV", "");
  const [kodVII, setKodVII] = useLocalStorage("kontekstP1KodResortowyVII", "");
  const [rolaPracownikaMedycznego, setRolaPracownikaMedycznego] = useLocalStorage(
    "kontekstP1ZgodyRolaUzytkownika",
    ZgodyPacjentaRolaPracownikaMedycznego.Lekarz
  );
  const [identyfikatorPracownika, setIdentyfikatorPracownika] = useLocalStorage(
    "kontekstP1IdentyfikatorUzytkownika",
    ""
  );
  const [tlsThumbprint, setTlsThumbprint] = useLocalStorage("kontekstP1TlsThumbprint", "");
  const [wssThumbprint, setWssThumbprint] = useLocalStorage("kontekstP1WssThumbprint", "");
  const [predefinedMode, setPredefinedMode] = useState(true);
  const [certs] = useDataLoader(() => environmentService.commonCertCache());
  const [cacheContexts] = useDataLoader(() => environmentService.commonP1CommunicationContextCache());
  const tlsCerts = useMemo(() => certs?.filter(x => x.typ === TypCertyfikatu.P1TlsPodmiot) || [], [certs]);
  const wssCerts = useMemo(() => certs?.filter(x => x.typ === TypCertyfikatu.P1WssPodmiot) || [], [certs]);

  useEffect(() => {
    onChange({
      ...value,
      typSwiadczeniodawcy,
      miejsceIzbyLekarskiej,
      kodResortowyI: kodI,
      kodResortowyV: kodV,
      kodResortowyVII: kodVII,
      rolaPracownikaMedycznego,
      identyfikatorPracownika,
      certyfikatTls: tlsThumbprint
        ? {
            cacheThumbprint: tlsThumbprint,
          }
        : undefined,
      certyfikatWss: wssThumbprint
        ? {
            cacheThumbprint: wssThumbprint,
          }
        : undefined,
    });
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTypSwiadczeniodawcy(value.typSwiadczeniodawcy || TypSwiadczeniodawcy.PodmiotLeczniczy);
    setMiejsceIzbyLekarskiej(value.miejsceIzbyLekarskiej);
    setKodI(value.kodResortowyI || "");
    setKodV(value.kodResortowyV || "");
    setKodVII(value.kodResortowyVII || "");
    setRolaPracownikaMedycznego(value.rolaPracownikaMedycznego || ZgodyPacjentaRolaPracownikaMedycznego.Lekarz);
    setIdentyfikatorPracownika(value.identyfikatorPracownika || "");
    setTlsThumbprint(value.certyfikatTls?.cacheThumbprint || "");
    setWssThumbprint(value.certyfikatWss?.cacheThumbprint || "");
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, [value]);

  if (predefinedMode) {
    return (
      <Form layout="vertical" condensed>
        <FormItem label="Wybierz kontekst z cache">
          <SelectInput
            value={undefined}
            onChange={x => x && onChange(convertP1CommunicationContextCacheEntry(x))}
            items={cacheContexts || []}
            display={formatKontekstZgodyDisplay}
            filtering="multitokenIncludes"
          />
        </FormItem>
        <FormItem label="Aktualne dane kontekstu">
          <div>
            {TypSwiadczeniodawcy[value.typSwiadczeniodawcy]} {value.kodResortowyI}
            {value.kodResortowyVII ? `-${value.kodResortowyVII}` : value.kodResortowyV ? `-${value.kodResortowyV}` : ""}
          </div>
          <div>
            {value.rolaPracownikaMedycznego
              ? ZgodyPacjentaRolaPracownikaMedycznego[value.rolaPracownikaMedycznego]
              : value.rolaPracownikaMedycznego}
            , {value.identyfikatorPracownika}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {value.certyfikatTls?.cacheThumbprint ? `Wybrano certyfikat TLS` : "Nie wybrano TLS"}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {value.certyfikatWss?.cacheThumbprint ? `Wybrano certyfikat WSS` : "Nie wybrano WSS"}
          </div>
          <div>
            <ClickableIcon icon={faPen} onClick={() => setPredefinedMode(false)}>
              Wprowadź ręcznie
            </ClickableIcon>
          </div>
        </FormItem>
      </Form>
    );
  } else {
    return (
      <Form layout="vertical" model={value} onChange={onChange} condensed>
        <FormItem label="Typ świadczeniodawcy">
          <SelectField
            name="typSwiadczeniodawcy"
            items={typySwiadczeniodawcow}
            display={x => TypSwiadczeniodawcy[x || TypSwiadczeniodawcy.PodmiotLeczniczy]}
            hideClearIcon
          />
        </FormItem>
        <FormItem label="Miejsce izby lekarskiej" hideIf={typSwiadczeniodawcy !== TypSwiadczeniodawcy.PraktykaLekarska}>
          <SelectField
            name="miejsceIzbyLekarskiej"
            items={miejscaIzbyLekarskiej}
            display={x => (x ? MiejsceOkregowejIzbyLekarskiej[x] || (x || "?").toString() : "-")}
          />
        </FormItem>
        <FormItem label="Kod resortowy I">
          <TextField name="kodResortowyI" />
        </FormItem>
        <FormItem label="Kod resortowy V (opcjonalnie)">
          <TextField name="kodResortowyV" />
        </FormItem>
        <FormItem label="Kod resortowy VII (opcjonalnie)">
          <TextField name="kodResortowyVII" />
        </FormItem>
        <FormItem label="Rola pracownika medycznego">
          <SelectField
            name="rolaPracownikaMedycznego"
            items={userRoles}
            display={x => (x ? ZgodyPacjentaRolaPracownikaMedycznego[x] : "")}
          />
        </FormItem>
        <FormItem label="Identyfikator pracownika">
          <TextField name="identyfikatorPracownika" />
        </FormItem>
        <FormNesting object="certyfikatTls" layout="vertical">
          <FormItem label="Certyfikat podmiotu TLS">
            <SelectField
              name="cacheThumbprint"
              items={tlsCerts}
              itemKeyAsModel
              itemKey="thumbprint"
              display="friendlyName"
            />
          </FormItem>
        </FormNesting>
        <FormNesting object="certyfikatWss" layout="vertical">
          <FormItem label="Certyfikat podmiotu WSS">
            <SelectField
              name="cacheThumbprint"
              items={wssCerts}
              itemKeyAsModel
              itemKey="thumbprint"
              display="friendlyName"
            />
          </FormItem>
        </FormNesting>
        <FormItem>
          <ClickableIcon icon={faList} onClick={() => setPredefinedMode(true)}>
            Wybierz z kontekstu
          </ClickableIcon>
        </FormItem>
      </Form>
    );
  }
}

function convertP1CommunicationContextCacheEntry(
  x: P1CommunicationContextCacheEntryDto
): ZgodyPacjentaKontekstPracownikaMedycznego {
  return {
    typSwiadczeniodawcy: x.typSwiadczeniodawcy || TypSwiadczeniodawcy.PodmiotLeczniczy,
    kodResortowyI: x.kodResortowyI,
    kodResortowyVII: x.kodResortowyVII,
    identyfikatorPracownika: x.identyfikatorUzytkownika,
    miejsceIzbyLekarskiej: x.miejsceIzbyLekarskiej,
    rolaPracownikaMedycznego:
      convertZmRolaUzytkownikaToRolaZgod(x.rolaUzytkownika) || ZgodyPacjentaRolaPracownikaMedycznego.Lekarz,
    certyfikatTls: x.certyfikatTlsThumbprint
      ? {
          cacheThumbprint: x.certyfikatTlsThumbprint,
        }
      : undefined,
    certyfikatWss: x.certyfikatWssThumbprint
      ? {
          cacheThumbprint: x.certyfikatWssThumbprint,
        }
      : undefined,
  };
}

function convertZmRolaUzytkownikaToRolaZgod(
  x: ZmRolaUzytkownika | undefined
): ZgodyPacjentaRolaPracownikaMedycznego | undefined {
  switch (x) {
    case ZmRolaUzytkownika.Lekarz:
      return ZgodyPacjentaRolaPracownikaMedycznego.Lekarz;
    case ZmRolaUzytkownika.LekarzDentysta:
      return ZgodyPacjentaRolaPracownikaMedycznego.LekarzDentysta;
    case ZmRolaUzytkownika.Felczer:
      return ZgodyPacjentaRolaPracownikaMedycznego.Felczer;
    case ZmRolaUzytkownika.Pielegniarka:
      return ZgodyPacjentaRolaPracownikaMedycznego.Pielegniarka;
    case ZmRolaUzytkownika.Polozna:
      return ZgodyPacjentaRolaPracownikaMedycznego.Polozna;
    case ZmRolaUzytkownika.Farmaceuta:
      return ZgodyPacjentaRolaPracownikaMedycznego.Farmaceuta;
    case ZmRolaUzytkownika.ProfesjonalistaMedyczny:
      return ZgodyPacjentaRolaPracownikaMedycznego.ProfesjonalistaMedyczny;
    case ZmRolaUzytkownika.PracownikAdministracyjny:
      return ZgodyPacjentaRolaPracownikaMedycznego.PracownikAdministracyjny;
    case ZmRolaUzytkownika.DiagnostaLaboratoryjny:
      return ZgodyPacjentaRolaPracownikaMedycznego.DiagnostaLaboratoryjny;
    default:
      return undefined;
  }
}

function formatKontekstZgodyDisplay(x: P1CommunicationContextCacheEntryDto): string {
  const typSwiadczeniodawcy = x.typSwiadczeniodawcy || TypSwiadczeniodawcy.PodmiotLeczniczy;
  let uzytkownik = x.rolaUzytkownika
    ? ZgodyPacjentaRolaPracownikaMedycznego[x.rolaUzytkownika]
    : `Rola ${x.rolaUzytkownika}`;
  if (x.imie || x.nazwisko) {
    uzytkownik = `${x.imie} ${x.nazwisko}, ${uzytkownik}`;
  }
  let kodMiejsca = x.kodResortowyI;
  if (x.kodResortowyVII) {
    kodMiejsca += `-${x.kodResortowyVII}`;
  } else if (x.kodResortowyV) {
    kodMiejsca += `-${x.kodResortowyV}`;
  }

  return [
    `${uzytkownik} ${x.identyfikatorUzytkownika}`,
    `${TypSwiadczeniodawcy[typSwiadczeniodawcy]} ${kodMiejsca}`,
    x.typSwiadczeniodawcy === TypSwiadczeniodawcy.PraktykaLekarska
      ? x.miejsceIzbyLekarskiej
        ? MiejsceOkregowejIzbyLekarskiej[x.miejsceIzbyLekarskiej]
        : "nieokreślona izba lekarska"
      : undefined,
  ]
    .filter(Boolean)
    .join(", ");
}
