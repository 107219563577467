import { faList, faPen } from "@fortawesome/free-solid-svg-icons";
import {
  ClickableIcon,
  Form,
  FormItem,
  FormNesting,
  SelectField,
  SelectInput,
  TextField,
  enumUtils,
  useDataLoader,
  useLocalStorage,
} from "@optimed/react";
import { environmentService } from "api";
import {
  KontekstZapytaniaZm,
  MiejsceOkregowejIzbyLekarskiej,
  P1CommunicationContextCacheEntryDto,
  TrybDostepuDoDanych,
  TypCertyfikatu,
  TypIdentyfikatoraPersoneluMedycznego,
  TypSwiadczeniodawcy,
  ZmRolaUzytkownika,
} from "api/models";
import { useEffect, useMemo, useState } from "react";

interface ZmContextFormProps {
  value: KontekstZapytaniaZm;
  onChange: (value: KontekstZapytaniaZm) => any;
}

const userRoles = enumUtils.getNumericValues(ZmRolaUzytkownika);
const userIdTypes = enumUtils.getNumericValues(TypIdentyfikatoraPersoneluMedycznego);
const typySwiadczeniodawcow = enumUtils.getNumericValues(TypSwiadczeniodawcy);
const accessModes = enumUtils.getNumericValues(TrybDostepuDoDanych);
const miejscaIzbyLekarskiej = enumUtils.getNumericValues(MiejsceOkregowejIzbyLekarskiej);

export function ZmContextForm(props: ZmContextFormProps) {
  const { value, onChange } = props;

  const [typSwiadczeniodawcy, setTypSwiadczeniodawcy] = useLocalStorage(
    "kontekstP1TypSwiadczeniodawcy",
    TypSwiadczeniodawcy.PodmiotLeczniczy
  );
  const [miejsceIzbyLekarskiej, setMiejsceIzbyLekarskiej] = useLocalStorage<MiejsceOkregowejIzbyLekarskiej | undefined>(
    "kontekstP1MiejsceIzbyLekarskiej",
    undefined
  );
  const [kodI, setKodI] = useLocalStorage("kontekstP1KodResortowyI", "");
  const [kodVII, setKodVII] = useLocalStorage("kontekstP1KodResortowyVII", "");
  const [nrKonta, setNrKonta] = useLocalStorage("kontekstP1NumerKontaUslugodawcyWP1", "");
  const [rolaUzytkownika, setRolaUzytkownika] = useLocalStorage("kontekstP1RolaUzytkownika", ZmRolaUzytkownika.Lekarz);
  const [typIdentyfikatoraUzytkownika, setTypIdentyfikatoraUzytkownika] = useLocalStorage(
    "kontekstP1TypIdentyfikatoraUzytkownika",
    TypIdentyfikatoraPersoneluMedycznego.Npwz
  );
  const [identyfikatorUzytkownika, setIdentyfikatorUzytkownika] = useLocalStorage(
    "kontekstP1IdentyfikatorUzytkownika",
    ""
  );
  const [tlsThumbprint, setTlsThumbprint] = useLocalStorage("kontekstP1TlsThumbprint", "");
  const [wssThumbprint, setWssThumbprint] = useLocalStorage("kontekstP1WssThumbprint", "");
  const [predefinedMode, setPredefinedMode] = useState(true);
  const [certs] = useDataLoader(() => environmentService.commonCertCache());
  const [cacheContexts] = useDataLoader(() => environmentService.commonP1CommunicationContextCache());
  const tlsCerts = useMemo(() => certs?.filter(x => x.typ === TypCertyfikatu.P1TlsPodmiot) || [], [certs]);
  const wssCerts = useMemo(() => certs?.filter(x => x.typ === TypCertyfikatu.P1WssPodmiot) || [], [certs]);

  useEffect(() => {
    onChange({
      ...value,
      typSwiadczeniodawcy,
      miejsceIzbyLekarskiej,
      kodResortowyI: kodI,
      kodResortowyVII: kodVII,
      rolaUzytkownika,
      typIdentyfikatoraUzytkownika,
      identyfikatorUzytkownika,
      numerKontaUslugodawcy: nrKonta,
      certyfikatTls: tlsThumbprint
        ? {
            cacheThumbprint: tlsThumbprint,
          }
        : undefined,
      certyfikatWss: wssThumbprint
        ? {
            cacheThumbprint: wssThumbprint,
          }
        : undefined,
    });
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTypSwiadczeniodawcy(value.typSwiadczeniodawcy || TypSwiadczeniodawcy.PodmiotLeczniczy);
    setMiejsceIzbyLekarskiej(value.miejsceIzbyLekarskiej);
    setKodI(value.kodResortowyI || "");
    setKodVII(value.kodResortowyVII || "");
    setNrKonta(value.numerKontaUslugodawcy || "");
    setRolaUzytkownika(value.rolaUzytkownika || ZmRolaUzytkownika.Lekarz);
    setTypIdentyfikatoraUzytkownika(value.typIdentyfikatoraUzytkownika || TypIdentyfikatoraPersoneluMedycznego.Npwz);
    setIdentyfikatorUzytkownika(value.identyfikatorUzytkownika || "");
    setTlsThumbprint(value.certyfikatTls?.cacheThumbprint || "");
    setWssThumbprint(value.certyfikatWss?.cacheThumbprint || "");
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, [value]);

  if (predefinedMode) {
    return (
      <Form layout="vertical" condensed>
        <FormItem label="Wybierz kontekst z cache">
          <SelectInput
            value={undefined}
            onChange={x => x && onChange(convertP1CommunicationContextCacheEntry(x))}
            items={cacheContexts || []}
            display={formatKontekstZmDisplay}
          />
        </FormItem>
        <FormItem label="Aktualne dane kontekstu">
          <div>
            {TypSwiadczeniodawcy[value.typSwiadczeniodawcy]} {value.kodResortowyI}
            {value.kodResortowyVII ? `-${value.kodResortowyVII}` : ""}, nr konta {value.numerKontaUslugodawcy}
          </div>
          <div>
            {value.rolaUzytkownika ? ZmRolaUzytkownika[value.rolaUzytkownika] : value.rolaUzytkownika},{" "}
            {
              TypIdentyfikatoraPersoneluMedycznego[
                value.typIdentyfikatoraUzytkownika || TypIdentyfikatoraPersoneluMedycznego.Npwz
              ]
            }{" "}
            {value.identyfikatorUzytkownika}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {value.certyfikatTls?.cacheThumbprint ? `Wybrano certyfikat TLS` : "Nie wybrano TLS"}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {value.certyfikatWss?.cacheThumbprint ? `Wybrano certyfikat WSS` : "Nie wybrano WSS"}
          </div>
          <div>
            <ClickableIcon icon={faPen} onClick={() => setPredefinedMode(false)}>
              Wprowadź ręcznie
            </ClickableIcon>
          </div>
        </FormItem>
      </Form>
    );
  } else {
    return (
      <Form layout="vertical" model={value} onChange={onChange} condensed>
        <FormItem label="Typ świadczeniodawcy">
          <SelectField
            name="typSwiadczeniodawcy"
            items={typySwiadczeniodawcow}
            display={x => TypSwiadczeniodawcy[x || TypSwiadczeniodawcy.PodmiotLeczniczy]}
            hideClearIcon
          />
        </FormItem>
        <FormItem label="Miejsce izby lekarskiej" hideIf={typSwiadczeniodawcy !== TypSwiadczeniodawcy.PraktykaLekarska}>
          <SelectField
            name="miejsceIzbyLekarskiej"
            items={miejscaIzbyLekarskiej}
            display={x => (x ? MiejsceOkregowejIzbyLekarskiej[x] || (x || "?").toString() : "-")}
          />
        </FormItem>
        <FormItem label="Kod resortowy I">
          <TextField name="kodResortowyI" />
        </FormItem>
        <FormItem label="Kod resortowy VII (opcjonalnie)">
          <TextField name="kodResortowyVII" />
        </FormItem>
        <FormItem label="Nr konta usługodawcy w P1">
          <TextField name="numerKontaUslugodawcy" restrict={/^\d*$/} />
        </FormItem>
        <FormItem label="Rola użytkownika">
          <SelectField
            name="rolaUzytkownika"
            items={userRoles}
            display={x => (x ? ZmRolaUzytkownika[x] : "")}
            hideClearIcon
          />
        </FormItem>
        <FormItem label="Typ identyfikatora użytkownika">
          <SelectField
            name="typIdentyfikatoraUzytkownika"
            items={userIdTypes}
            display={x => TypIdentyfikatoraPersoneluMedycznego[x || TypIdentyfikatoraPersoneluMedycznego.Npwz]}
            hideClearIcon
          />
        </FormItem>
        <FormItem label="Identyfikator użytkownika (npwz/pesel)">
          <TextField name="identyfikatorUzytkownika" />
        </FormItem>

        <FormNesting object="certyfikatTls" layout="vertical">
          <FormItem label="Certyfikat podmiotu TLS">
            <SelectField
              name="cacheThumbprint"
              items={tlsCerts}
              itemKeyAsModel
              itemKey="thumbprint"
              display="friendlyName"
            />
          </FormItem>
        </FormNesting>

        <FormNesting object="certyfikatWss" layout="vertical">
          <FormItem label="Certyfikat podmiotu WSS">
            <SelectField
              name="cacheThumbprint"
              items={wssCerts}
              itemKeyAsModel
              itemKey="thumbprint"
              display="friendlyName"
            />
          </FormItem>
        </FormNesting>

        <FormItem label="Tryb dostępu do danych">
          <SelectField
            name="trybDostepuDoDanych"
            items={accessModes}
            display={x => (x ? TrybDostepuDoDanych[x] : "Standardowy")}
          />
        </FormItem>
        <FormItem>
          <ClickableIcon icon={faList} onClick={() => setPredefinedMode(true)}>
            Wybierz z kontekstu
          </ClickableIcon>
        </FormItem>
      </Form>
    );
  }
}

function convertP1CommunicationContextCacheEntry(x: P1CommunicationContextCacheEntryDto): KontekstZapytaniaZm {
  return {
    typSwiadczeniodawcy: x.typSwiadczeniodawcy || TypSwiadczeniodawcy.PodmiotLeczniczy,
    kodResortowyI: x.kodResortowyI,
    kodResortowyVII: x.kodResortowyVII,
    typIdentyfikatoraUzytkownika: x.typIdentyfikatoraUzytkownika,
    identyfikatorUzytkownika: x.identyfikatorUzytkownika,
    numerKontaUslugodawcy: x.numerKontaUslugodawcy.toString(),
    miejsceIzbyLekarskiej: x.miejsceIzbyLekarskiej,
    rolaUzytkownika: x.rolaUzytkownika || ZmRolaUzytkownika.Lekarz,
    certyfikatTls: x.certyfikatTlsThumbprint
      ? {
          cacheThumbprint: x.certyfikatTlsThumbprint,
        }
      : undefined,
    certyfikatWss: x.certyfikatWssThumbprint
      ? {
          cacheThumbprint: x.certyfikatWssThumbprint,
        }
      : undefined,
  };
}

function formatKontekstZmDisplay(x: P1CommunicationContextCacheEntryDto): string {
  const typSwiadczeniodawcy = x.typSwiadczeniodawcy || TypSwiadczeniodawcy.PodmiotLeczniczy;
  let uzytkownik = x.rolaUzytkownika ? ZmRolaUzytkownika[x.rolaUzytkownika] : `Rola ${x.rolaUzytkownika}`;
  if (x.imie || x.nazwisko) {
    uzytkownik = `${x.imie} ${x.nazwisko}, ${uzytkownik}`;
  }
  let kodMiejsca = x.kodResortowyI;
  if (x.kodResortowyVII) {
    kodMiejsca += `-${x.kodResortowyVII}`;
  } else if (x.kodResortowyV) {
    kodMiejsca += `-${x.kodResortowyV}`;
  }

  return [
    `${uzytkownik} ${TypIdentyfikatoraPersoneluMedycznego[x.typIdentyfikatoraUzytkownika || 0]} ${
      x.identyfikatorUzytkownika
    }`,
    `${TypSwiadczeniodawcy[typSwiadczeniodawcy]} ${kodMiejsca}`,
    x.typSwiadczeniodawcy === TypSwiadczeniodawcy.PraktykaLekarska
      ? x.miejsceIzbyLekarskiej
        ? MiejsceOkregowejIzbyLekarskiej[x.miejsceIzbyLekarskiej]
        : "nieokreślona izba lekarska"
      : undefined,
    `nr konta ${x.numerKontaUslugodawcy}`,
  ]
    .filter(Boolean)
    .join(", ");
}
