import { Grid, GridItem, LoadingRegion } from "@optimed/react";
import React from "react";
import { receptyMaintenanceService } from "../../../api";
import { ReceptyDaneWyszukiwaniaLokalnychRecept, ReceptyWynikWyszukiwaniaLokalnychRecept } from "../../../api/models";
import { FindPrescriptionsCriteria } from "./FindPrescriptionsCriteria";
import { FindPrescriptionsResult } from "./FindPrescriptionsResult";

interface FindPrescriptionsPageProps {}

interface FindPrescriptionsPageState {
  criteria: ReceptyDaneWyszukiwaniaLokalnychRecept;
  promise?: Promise<any>;
  pending: boolean;
  result?: ReceptyWynikWyszukiwaniaLokalnychRecept;
}

export class FindPrescriptionsPage extends React.Component<FindPrescriptionsPageProps, FindPrescriptionsPageState> {
  constructor(props: FindPrescriptionsPageProps) {
    super(props);

    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
    this.handleCriteriaSubmit = this.handleCriteriaSubmit.bind(this);

    this.state = {
      criteria: {},
      pending: false,
    };
  }

  render() {
    const result = this.state.result;

    return (
      <Grid gap>
        <GridItem colTemplate="250px" scrollable>
          <FindPrescriptionsCriteria
            criteria={this.state.criteria}
            onChange={this.handleCriteriaChange}
            onSubmit={this.handleCriteriaSubmit}
          />
          {result && (
            <div>
              Pasujące rekordy {result.totalCount}, pobrano {result.returnedCount}
            </div>
          )}
        </GridItem>
        <GridItem colTemplate="1fr" scrollable>
          <LoadingRegion promise={this.state.promise}>
            <FindPrescriptionsResult result={this.state.result} />
          </LoadingRegion>
        </GridItem>
      </Grid>
    );
  }

  handleCriteriaChange(newCriteria: ReceptyDaneWyszukiwaniaLokalnychRecept) {
    this.setState({ criteria: newCriteria });
  }

  handleCriteriaSubmit(criteria: ReceptyDaneWyszukiwaniaLokalnychRecept) {
    if (this.state.pending) return;

    const promise = receptyMaintenanceService
      .wyszukajWystawioneRecepty(criteria)
      .then(res => {
        this.setState({
          result: res,
        });
      })
      .finally(() => this.setState({ pending: false }));

    this.setState({
      criteria,
      promise,
      pending: true,
    });
  }
}
