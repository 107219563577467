import {
  Checkbox,
  Form,
  FormItem,
  Grid,
  GridItem,
  LoadingRegion,
  SubmitButton,
  Table,
  TableHeader,
  TableScrollableContainer,
  Text,
  TextInput,
  Th,
  useDataLoaderWithPendingOperation,
  useLocalStorage,
} from "@optimed/react";
import { receptyService } from "api";
import {
  ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzoneZapytanieZKontekstemP1,
  ReceptyKontekstWywolaniaP1,
  ReceptyStatusReceptyWp1,
  RodzajIdentyfikatoraOsoby,
  TypSwiadczeniodawcy,
  ZawodMedyczny,
} from "api/models";
import { useState } from "react";
import { ReceptaContextForm } from "..";

export function RozszerzoneWyszukiwanieReceptPage() {
  const [p1Ctx, setP1Ctx] = useState<ReceptyKontekstWywolaniaP1>({
    typSwiadczeniodawcy: TypSwiadczeniodawcy.PodmiotLeczniczy,
    npwz: "1091231",
    numerKontaUslugodawcy: "51",
    kodResortowyI: "000000814987",
    kodResortowyVII: "001",
    zawodMedyczny: ZawodMedyczny.Lekarz,
  });
  const [pacjentPesel, setPacjentPesel] = useLocalStorage("pacjentPesel", "");
  const [emergencyMode, setEmergencyMode] = useState(false);
  const [request, setRequest] = useState<ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzoneZapytanieZKontekstemP1>();
  const [response, op] = useDataLoaderWithPendingOperation(
    async () => (request ? receptyService.rozszerzoneWyszukanieReceptUslugobiorcy(request) : undefined),
    [request]
  );

  const execute = () => {
    setRequest({
      kontekstP1: p1Ctx,
      payload: {
        sytuacjaZagrozeniaZycia: emergencyMode,
        pacjent: {
          rodzajIdentyfikatora: RodzajIdentyfikatoraOsoby.Pesel,
          identyfikator: pacjentPesel,
        },
      },
    });
  };

  return (
    <Grid gap>
      <GridItem colTemplate="300px" scrollable>
        <h5>Kontekst komunikacji z P1</h5>
        <ReceptaContextForm value={p1Ctx} onChange={setP1Ctx} />
        <h5>Dodatkowe parametry</h5>
        <Form layout="vertical">
          <FormItem label="Pesel pacjenta">
            <TextInput value={pacjentPesel} onChange={setPacjentPesel} />
          </FormItem>
          <FormItem>
            <Checkbox value={emergencyMode} onChange={setEmergencyMode}>
              Sytuacja zagrożenia życia
            </Checkbox>
          </FormItem>
          {
            emergencyMode && 
            <FormItem>
              <Text danger >
                Każdy przypadek wyszukiwania recept z zastosowaniem flagi w sytuacji zagrożenia życia jest odnotowywany w systemie P1 w celu monitorowania ew. nadużyć.
              </Text>
            </FormItem>
          }
          <SubmitButton onClick={execute} disabled={op.state.pending}>
            Wykonaj zapytanie
          </SubmitButton>
        </Form>
      </GridItem>
      <GridItem colTemplate="1fr">
        <LoadingRegion promise={op.promise}>
          <Grid gap>
            <GridItem rowTemplate="auto">Liczba recept: {response?.liczbaRecept}</GridItem>
            <GridItem rowTemplate="1fr">
              <TableScrollableContainer>
                <Table>
                  <TableHeader>
                    <Th>Data wystawienia</Th>
                    <Th>Status</Th>
                    <Th>Szczegóły</Th>
                  </TableHeader>
                  {response?.recepty?.map((x, i) => (
                    <tr key={i}>
                      <td>{x.dataWystawienia}</td>
                      <td>{ReceptyStatusReceptyWp1[x.status]}</td>
                      <td>
                        <div>{x.nazwaLeku}</div>
                        <div>EAN:{x.ean}</div>
                        <div>Klucz recepty: {x.kluczRecepty}</div>
                      </td>
                    </tr>
                  ))}
                </Table>
              </TableScrollableContainer>
            </GridItem>
          </Grid>
        </LoadingRegion>
      </GridItem>
    </Grid>
  );
}
