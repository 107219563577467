// File generated automatically, do not modify

import axios from 'axios';
import { ZgodyPacjentaZgodaPacjentaNaDostepDoDanychKryteriaZgodaPacjentaRequest, ZgodyPacjentaZgodaPacjentaNaDostepDoDanychPacjentaResponse, ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteriaZgodaPacjentaRequest, ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuResponse, ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaKryteriaZgodaPacjentaRequest, ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaResponse, ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaKryteriaZgodaPacjentaRequest, ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaResponse, ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaKryteriaZgodaPacjentaRequest, ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaResponse, ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest, ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneResponse, ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest, ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneResponse, ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest, ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneResponse } from './models';

const paths = {
  zgodaNaDostepDoDanychPacjenta: 'api/zgody/ZgodaNaDostepDoDanychPacjenta',
  zgodaNaDostepDoDokumentacjiPacjentaPrzezPracownikaPodmiot: 'api/zgody/ZgodaNaDostepDoDokumentacjiPacjentaPrzezPracownikaPodmiot',
  wyslijWniosekONadanieUprawnienDoDanychPacjenta: 'api/zgody/WyslijWniosekONadanieUprawnienDoDanychPacjenta',
  autoryzujZadanieNadaniaUprawnienDoDanychPacjenta: 'api/zgody/AutoryzujZadanieNadaniaUprawnienDoDanychPacjenta',
  odczytajKanalyKomunikacjiPacjenta: 'api/zgody/OdczytajKanalyKomunikacjiPacjenta',
  zapisZgodyNaSwiadczenieMedyczne: 'api/zgody/ZapisZgodyNaSwiadczenieMedyczne',
  odczytajZgodeNaSwiadczenieMedyczne: 'api/zgody/OdczytajZgodeNaSwiadczenieMedyczne',
  wyszukajZgodyNaSwiadczenieMedyczne: 'api/zgody/WyszukajZgodyNaSwiadczenieMedyczne',
};

function zgodaNaDostepDoDanychPacjenta(data: ZgodyPacjentaZgodaPacjentaNaDostepDoDanychKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaZgodaPacjentaNaDostepDoDanychPacjentaResponse> {
  return axios.request<ZgodyPacjentaZgodaPacjentaNaDostepDoDanychPacjentaResponse>({
    url: paths.zgodaNaDostepDoDanychPacjenta,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zgodaNaDostepDoDokumentacjiPacjentaPrzezPracownikaPodmiot(data: ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuResponse> {
  return axios.request<ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuResponse>({
    url: paths.zgodaNaDostepDoDokumentacjiPacjentaPrzezPracownikaPodmiot,
    method: 'post',
    data,
  }).then(res => res.data);
}

function wyslijWniosekONadanieUprawnienDoDanychPacjenta(data: ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaResponse> {
  return axios.request<ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaResponse>({
    url: paths.wyslijWniosekONadanieUprawnienDoDanychPacjenta,
    method: 'post',
    data,
  }).then(res => res.data);
}

function autoryzujZadanieNadaniaUprawnienDoDanychPacjenta(data: ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaResponse> {
  return axios.request<ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaResponse>({
    url: paths.autoryzujZadanieNadaniaUprawnienDoDanychPacjenta,
    method: 'post',
    data,
  }).then(res => res.data);
}

function odczytajKanalyKomunikacjiPacjenta(data: ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaResponse> {
  return axios.request<ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaResponse>({
    url: paths.odczytajKanalyKomunikacjiPacjenta,
    method: 'post',
    data,
  }).then(res => res.data);
}

function zapisZgodyNaSwiadczenieMedyczne(data: ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneResponse> {
  return axios.request<ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneResponse>({
    url: paths.zapisZgodyNaSwiadczenieMedyczne,
    method: 'post',
    data,
  }).then(res => res.data);
}

function odczytajZgodeNaSwiadczenieMedyczne(data: ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneResponse> {
  return axios.request<ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneResponse>({
    url: paths.odczytajZgodeNaSwiadczenieMedyczne,
    method: 'post',
    data,
  }).then(res => res.data);
}

function wyszukajZgodyNaSwiadczenieMedyczne(data: ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest): Promise<ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneResponse> {
  return axios.request<ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneResponse>({
    url: paths.wyszukajZgodyNaSwiadczenieMedyczne,
    method: 'post',
    data,
  }).then(res => res.data);
}

export const zgodyPacjentaService = {
  paths,
  zgodaNaDostepDoDanychPacjenta,
  zgodaNaDostepDoDokumentacjiPacjentaPrzezPracownikaPodmiot,
  wyslijWniosekONadanieUprawnienDoDanychPacjenta,
  autoryzujZadanieNadaniaUprawnienDoDanychPacjenta,
  odczytajKanalyKomunikacjiPacjenta,
  zapisZgodyNaSwiadczenieMedyczne,
  odczytajZgodeNaSwiadczenieMedyczne,
  wyszukajZgodyNaSwiadczenieMedyczne,
};
