// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ReceptyDaneDoWystawieniaPakietuReceptXmlZapytanieZKontekstemP1, ReceptyWeryfikacjaPakietuReceptResult, ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1, ReceptyTestControllerXmlPayload, ReceptyDaneWyszukiwaniaLokalnychRecept, ReceptyWynikWyszukiwaniaLokalnychRecept } from './models';

const paths = {
  weryfikacjaPakietuReceptZXml: 'api/Recepty/Test/WeryfikacjaPakietuReceptZXml',
  wydrukPakietu: 'api/Recepty/Test/WydrukPakietu',
  wydrukRecepty: 'api/Recepty/Test/WydrukRecepty',
  wydrukPakietuZXml: 'api/Recepty/Test/WydrukPakietuZXml',
  wydrukReceptyZXml: 'api/Recepty/Test/WydrukReceptyZXml',
  wyszukajWystawioneRecepty: 'api/Recepty/Test/WyszukajWystawioneRecepty',
};

interface WeryfikacjaPakietuReceptZXmlParams {
  zwrocXml?: boolean;
}

function weryfikacjaPakietuReceptZXml(args: { params: WeryfikacjaPakietuReceptZXmlParams; data: ReceptyDaneDoWystawieniaPakietuReceptXmlZapytanieZKontekstemP1; }): Promise<ReceptyWeryfikacjaPakietuReceptResult> {
  const { params, data } = args;
  return axios.request<ReceptyWeryfikacjaPakietuReceptResult>({
    url: paths.weryfikacjaPakietuReceptZXml,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function wydrukPakietu(data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1): Promise<string> {
  return axios.request<string>({
    url: paths.wydrukPakietu,
    method: 'post',
    data,
  }).then(res => res.data);
}

interface WydrukReceptyParams {
  index?: number;
}

function wydrukRecepty(args: { params: WydrukReceptyParams; data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1; }): Promise<string> {
  const { params, data } = args;
  return axios.request<string>({
    url: paths.wydrukRecepty,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function wydrukPakietuZXml(data: ReceptyTestControllerXmlPayload): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukPakietuZXml,
    method: 'post',
    responseType: 'blob',
    data,
  }).then(convertBlobResponseToFile);
}

function wydrukReceptyZXml(data: ReceptyTestControllerXmlPayload): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukReceptyZXml,
    method: 'post',
    responseType: 'blob',
    data,
  }).then(convertBlobResponseToFile);
}

function wyszukajWystawioneRecepty(data: ReceptyDaneWyszukiwaniaLokalnychRecept): Promise<ReceptyWynikWyszukiwaniaLokalnychRecept> {
  return axios.request<ReceptyWynikWyszukiwaniaLokalnychRecept>({
    url: paths.wyszukajWystawioneRecepty,
    method: 'post',
    data,
  }).then(res => res.data);
}

function convertBlobResponseToFile(res: AxiosResponse<Blob>): File {
  const contentDispositionHeader = res.headers['content-disposition'] || '';
  const filenameMatch = /filename="?([^;,"]+)/.exec(contentDispositionHeader);
  const filename = filenameMatch ? filenameMatch[1] : '';
  return new File([res.data], filename);
}

export const receptyTestService = {
  paths,
  weryfikacjaPakietuReceptZXml,
  wydrukPakietu,
  wydrukRecepty,
  wydrukPakietuZXml,
  wydrukReceptyZXml,
  wyszukajWystawioneRecepty,
};
