import { faEnvelope, faWrench } from "@fortawesome/free-solid-svg-icons";
import {
  Icon,
  Nav,
  NavDropdown,
  NavDropdownItem,
  NavItem,
  Navbar,
  NavbarComarchBrand,
  NavbarLoggedUser,
} from "@optimed/react";
import { routes } from "../routing/routes";
import { AppVersionInfo } from "./AppVersionInfo";

const receptyDropdown: NavDropdownItem[] = [
  {
    to: routes.prescriptions.find,
    label: "Wystawione recepty",
  },
  {
    to: routes.prescriptions.manualXml,
    label: "Ręczna wysyłka XML",
  },
  {
    to: routes.prescriptions.findInP1,
    label: "Recepty usługobiorcy w P1",
  },
];

const zmDropdown: NavDropdownItem[] = [
  {
    to: routes.encounters.queryP1,
    label: "Wyszukiwanie zdarzeń w P1",
  },
  {
    to: routes.encounters.rawFhirGet,
    label: "Serwisowe zapytanie FHIR",
  },
];

const patientConsestsDropdown: NavDropdownItem[] = [
  {
    to: routes.patientConsents.read,
    label: "Odczyt danych zgód",
  },
];

export function TopMenu() {
  return (
    <Navbar fixedTop>
      <NavbarComarchBrand appName="Optimed P1" navTo={routes.prescriptions.find} />

      <Nav>
        <NavDropdown items={receptyDropdown} dropdownCaret>
          Recepty
        </NavDropdown>
        <NavDropdown items={zmDropdown} dropdownCaret>
          Zdarzenia medyczne
        </NavDropdown>
        <NavItem to={routes.referrals.read}>
          <Icon icon={faEnvelope} /> Skierowania
        </NavItem>
        <NavDropdown items={patientConsestsDropdown} dropdownCaret>
          Zgody pacjenta
        </NavDropdown>
        <NavItem to={routes.serviceOperations}>
          <Icon icon={faWrench} /> Operacje serwisowe
        </NavItem>
      </Nav>

      <NavbarLoggedUser />
      <AppVersionInfo />
    </Navbar>
  );
}
